import React, { useEffect, useState } from 'react';
import BreadCrumb from '../partials/BreadCrumb';
import CardHeader from '../partials/miniComponent/CardHeader/CardHeader';
import axios from 'axios';
import Constants from '../../Constants';
import Pagination from 'react-js-pagination';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import Loader from '../partials/miniComponent/Loader';
import NoDataFound from '../partials/miniComponent/NoDataFound';



const Dashboard = () => {

    const [input2, setInput2] = useState({
        order_by: 'created_at',
        per_page: 10,
        direction: 'desc',
        search: '',
        startDate: '',  // Nouvelle entrée pour la date de début
        endDate: '',    // Nouvelle entrée pour la date de fin
    });
    
    const [isLoading, setIsLoading] = useState(false);
    const [itemsCountPerPageData, setItemsCounterPerPageData] = useState(0);
    const [totalItemsCountData, setTotalItemsCountData] = useState(1);
    const [startFromData, setStartFromData] = useState('');
    const [activePageData, setActivePageData] = useState(1);;
    const [datas, setDatas] = useState([]);
    const [profils, setProfils] = useState([]);
    const [selectedProfile, setSelectedProfile] = useState('');
    

      
    const handleProfileChange = (e) => {
      setSelectedProfile(e.target.value);
    };
    
    const handleInput = (e) => {
        setInput2(prevState => ({ ...prevState, [e.target.name]: e.target.value }));
    }
    

 /*
    const getDatas = (pageNumber) =>{
        setIsLoadingData(true);
        axios.get(`${Constants.BASE_URL}/data?page=${pageNumber}&search=${input2.search}&order_by=${input2.order_by}&per_page=${input2.per_page}&direction=${input2.direction}`).then(res => {        
            setDatas(res.data.data);
            setItemsCounterPerPageData(res.data.meta.per_page);
            setStartFromData(res.data.meta.from);
            setTotalItemsCountData(res.data.meta.total);
            setActivePageData(res.data.meta.current_page);
            setIsLoadingData(false);
            console.log(res.data);
        })
    }*/

    /*
    const getDatas = (pageNumber) => {
        setIsLoading(true);      
        axios.get(`${Constants.BASE_URL}/data?page=${pageNumber}&search=${input2.search}&order_by=${input2.order_by}&per_page=${input2.per_page}&direction=${input2.direction}&profile=${selectedProfile}`)
          .then(res => {
            setDatas(res.data.data);
            setItemsCounterPerPageData(res.data.meta.per_page);
            setStartFromData(res.data.meta.from);
            setTotalItemsCountData(res.data.meta.total);
            setActivePageData(res.data.meta.current_page);
            setIsLoading(false);
            console.log(res.data);
          })
          .catch(error => {
            setIsLoading(false);
            console.error(error);
          });
      };
*/         

      const getDatas = (pageNumber) => {
        setIsLoading(true);
        axios.get(`${Constants.BASE_URL}/data?page=${pageNumber}&search=${input2.search}&order_by=${input2.order_by}&per_page=${input2.per_page}&direction=${input2.direction}&profile=${selectedProfile}&start_date=${input2.startDate}&end_date=${input2.endDate}`)
            .then(res => {
                setDatas(res.data.data);
                setItemsCounterPerPageData(res.data.meta.per_page);
                setStartFromData(res.data.meta.from);
                setTotalItemsCountData(res.data.meta.total);
                setActivePageData(res.data.meta.current_page);
                setIsLoading(false);
                //console.log(res.data);
            })
            .catch(error => {
                setIsLoading(false);
                console.error(error);
            });
    };
    

    useEffect(() => {
      getDatas();
    }, [])


    useEffect(() => {
        // Obtenir la liste des profils
        axios.get(`${Constants.BASE_URL}/postes`)
          .then(res => {
            setProfils(res.data.postes);
            //console.log(res.data.postes);
          })
          .catch(error => {
            console.error(error);
          });
      
        // Appeler la fonction getDatas
        getDatas(1);
      }, []);
    


  const [userCount, setUserCount] = useState(0);
  const [permissionCount, setPermissionCount] = useState(0);
  const [roleCount, setRoleCount] = useState(0);
  const [projectCount, setProjectCount] = useState(0);
  const [comiteCount, setComiteCount] = useState(0);
  const [pasteursCount, setPasteursCount] = useState('');
  const [anciensCount, setAnciensCount] = useState('');
  const [diacresCount, setDiacresCount] = useState('');
  const [diaconesseCount, setDiaconesseCount] = useState('');
  const [fideleCount, setFideleCount] = useState('');

  useEffect(() => {
    // Fonction pour récupérer les statistiques
    const fetchStats = async () => {
      try {
        const [userResponse, permissionResponse, roleResponse, projectResponse] = await Promise.all([
          
          axios.get(`${Constants.BASE_URL}/userscount`).then(
            (res=>setUserCount(res.data.userCount))
          ),

          axios.get(`${Constants.BASE_URL}/permissionscount`).then(
            (res=>setPermissionCount(res.data.permissionCount))
          ),          

          axios.get(`${Constants.BASE_URL}/rolecount`).then(
            (res=>setRoleCount(res.data.roleCount))
          ),          

          axios.get(`${Constants.BASE_URL}/projetscount`).then(
            (res=>setProjectCount(res.data.projectCount))
          ),          

          axios.get(`${Constants.BASE_URL}/comitecount`).then(
            (res=>setComiteCount(res.data.comiteCount))
          ),          
          
          axios.get(`${Constants.BASE_URL}/pasteurcount`).then(
            (res=>setPasteursCount(res.data.pasteurcount))
          ),          

          axios.get(`${Constants.BASE_URL}/anciencount`).then(
            (res=>setAnciensCount(res.data.anciencount))
          ),          

          axios.get(`${Constants.BASE_URL}/diacrecount`).then(
            (res=>setDiacresCount(res.data.diacrecount))
          ),          

          axios.get(`${Constants.BASE_URL}/diaconessecount`).then(
            (res=>setDiaconesseCount(res.data.diaconessecount))
          ),          

          axios.get(`${Constants.BASE_URL}/fidelecount`).then(
            (res=>setFideleCount(res.data.fidelecount))
          ),          


        ]);

        const completedProjectResponse = await axios.get('/api/projects/completed/count');



        //setPermissionCount(permissionResponse.data.count);
        //setRoleCount(roleResponse.data.count);
        //setProjectCount(projectResponse.data.count);
        //setCompletedProjectCount(completedProjectResponse.data.count);
      } catch (error) {
        console.error('Error fetching dashboard stats:', error);
      }
    };

    fetchStats();
  }, []); // Le tableau de dépendances vide signifie que cela s'exécutera une seule fois après le montage initial.


    return (
        <>
            
            <div className='row' style={{marginTop:40}}>
<h3 style={{fontWeight:'bolder'}} className='mb-2'>Tableau de bord</h3> 

<div class="card col-md-3 mt-2 mb-4" style={{border:0}}>
  <div style={{border:'1px solid #cecece', borderRadius:10, boxShadow:'rgba(0, 0, 0, 0.40) 0.5px 0px 0.2rem 0.1rem', background:'white', color:''}}>
    <div class="card-body">
      <h5 class="card-title" style={{fontWeight:'bolder'}}> Utilisateurs</h5>
      <h5 class="card-title float-end" style={{fontSize:35}}><i className='fa fa-users' style={{fontSize:32, color:'purple'}}></i> {userCount}</h5>
    </div>
  </div>
  </div>

  <div class="card col-md-3 mt-2 mb-4" style={{border:0}}>
  <div style={{border:'1px solid #cecece', borderRadius:10, boxShadow:'rgba(0, 0, 0, 0.40) 0.5px 0px 0.2rem 0.1rem', background:'white', color:''}}>
    <div class="card-body">
      <h5 class="card-title" style={{fontWeight:'bolder'}}> Permissions</h5>
      <h5 class="card-title float-end" style={{fontSize:35}}><i className='fa fa-lock' style={{fontSize:32, color:'purple'}}></i> {permissionCount}</h5>
    </div>
  </div>
  </div>

  <div class="card col-md-3 mt-2 mb-4" style={{border:0}}>
  <div style={{border:'1px solid #cecece', borderRadius:10, boxShadow:'rgba(0, 0, 0, 0.40) 0.5px 0px 0.2rem 0.1rem', background:'white', color:''}}>
    <div class="card-body">
      <h5 class="card-title" style={{fontWeight:'bolder'}}>Rôles</h5>
      <h5 class="card-title float-end" style={{fontSize:35}}><i className='fa fa-user-group' style={{fontSize:32, color:'purple'}}></i> {roleCount}</h5>
    </div>
  </div>
  </div>

  <div class="card col-md-3 mt-2 mb-4" style={{border:0}}>
  <div style={{border:'1px solid #cecece', borderRadius:10, boxShadow:'rgba(0, 0, 0, 0.40) 0.5px 0px 0.2rem 0.1rem', background:'white', color:''}}>
    <div class="card-body">
      <h5 class="card-title" style={{fontWeight:'bolder'}}>Comités</h5>
      <h5 class="card-title float-end" style={{fontSize:35}}><i className='fa-solid fa-people-group' style={{fontSize:32, color:'purple'}}></i> {comiteCount}</h5>
    </div>
  </div>
  </div>
  <div class="card col-md-3 mt-2 mb-4" style={{border:0}}>
  <div style={{border:'1px solid #cecece', borderRadius:10, boxShadow:'rgba(0, 0, 0, 0.40) 0.5px 0px 0.2rem 0.1rem', background:'white', color:''}}>
    <div class="card-body">
      <h5 class="card-title" style={{fontWeight:'bolder'}}>Pasteurs</h5>
      <h5 class="card-title float-end" style={{fontSize:35}}><i className='fa-solid fa-user' style={{fontSize:32, color:'purple'}}></i> {pasteursCount}</h5>
    </div>
  </div>
  </div>
  <div class="card col-md-3 mt-2 mb-4" style={{border:0}}>
  <div style={{border:'1px solid #cecece', borderRadius:10, boxShadow:'rgba(0, 0, 0, 0.40) 0.5px 0px 0.2rem 0.1rem', background:'white', color:''}}>
    <div class="card-body">
      <h5 class="card-title" style={{fontWeight:'bolder'}}>Anciens</h5>
      <h5 class="card-title float-end" style={{fontSize:35}}><i className='fa-solid fa-user' style={{fontSize:32, color:"purple"}}></i> {anciensCount}</h5>
    </div>
  </div>
  </div>
  <div class="card col-md-3 mt-2 mb-4" style={{border:0}}>
  <div style={{border:'1px solid #cecece', borderRadius:10, boxShadow:'rgba(0, 0, 0, 0.40) 0.5px 0px 0.2rem 0.1rem', background:'white', color:''}}>
    <div class="card-body">
      <h5 class="card-title" style={{fontWeight:'bolder'}}>Diacres</h5>
      <h5 class="card-title float-end" style={{fontSize:35}}><i className='fa-solid fa-user' style={{fontSize:32, color:'purple'}}></i> {diacresCount}</h5>
    </div>
  </div>
  </div>
  <div class="card col-md-3 mt-2 mb-4" style={{border:0}}>
  <div style={{border:'1px solid #cecece', borderRadius:10, boxShadow:'rgba(0, 0, 0, 0.40) 0.5px 0px 0.2rem 0.1rem', background:'white', color:''}}>
    <div class="card-body">
      <h5 class="card-title" style={{fontWeight:'bolder'}}>Diaconnesses</h5>
      <h5 class="card-title float-end" style={{fontSize:35}}><i className='fa-solid fa-user' style={{fontSize:32, color:'purple'}}></i> {diaconesseCount}</h5>
    </div>
  </div>
  </div>
  <div class="card col-md-3 mt-2 mb-4" style={{border:0}}>
  <div style={{border:'1px solid #cecece', borderRadius:10, boxShadow:'rgba(0, 0, 0, 0.40) 0.5px 0px 0.2rem 0.1rem', background:'white', color:''}}>
    <div class="card-body">
      <h5 class="card-title" style={{fontWeight:'bolder'}}>Fidèles</h5>
      <h5 class="card-title float-end" style={{fontSize:35}}><i className='fa-solid fa-user' style={{fontSize:32, color:'purple'}}></i> {fideleCount}</h5>
    </div>
  </div>
  </div>

{/*
  <div class="card col-md-4 mt-2 mb-4" style={{border:0}}>
  <div style={{border:'1px solid #cecece', borderRadius:10, boxShadow:'rgba(0, 0, 0, 0.40) 0.5px 0px 0.2rem 0.1rem', background:'#2196F3', color:'white'}}>
    <div class="card-body">
      <h5 class="card-title" style={{fontWeight:'bolder'}}>Total projets</h5>
      <h5 class="card-title float-end"><i className='fa fa-folder' style={{fontSize:62}}></i> {projectCount}</h5>
    </div>
  </div>
  </div>
 */}

{
  /*
    <div class="card col-md-4 mt-2 mb-4" style={{border:0}}>
  <div style={{border:'1px solid #cecece', borderRadius:10, boxShadow:'rgba(0, 0, 0, 0.40) 0.5px 0px 0.2rem 0.1rem'}}>
    <div class="card-body">
      <h5 class="card-title">Total projets traités</h5>
      <h5 class="card-title float-end">20</h5>
    </div>
  </div>
  </div>

  <div class="card col-md-4 mt-2 mb-4" style={{border:0}}>
  <div style={{border:'1px solid #cecece', borderRadius:10, boxShadow:'rgba(0, 0, 0, 0.40) 0.5px 0px 0.2rem 0.1rem'}}>
    <div class="card-body">
      <h5 class="card-title">Total projets restants</h5>
      <h5 class="card-title float-end">20</h5>
    </div>
  </div>
  </div>
   */
}

</div>
   

        </>
    );
};

export default Dashboard;