// AxiosInterceptor.js

import axios from "axios";
import { redirect, useNavigate } from "react-router-dom";
import GlobalFunction from './GlobalFunction';
import { useHistory } from 'react-router-dom';

// Ajoute un intercepteur de demande
const requestInterceptor = axios.interceptors.request.use(function (config) {  

  if (localStorage.token != undefined) {
    config.headers['Authorization'] = `Bearer ${localStorage.token}`;
  }
  return config;
}, function (error) {
  return Promise.reject(error);
});


// Ajoute un intercepteur de réponse
const responseInterceptor = axios.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  if (error.response.status === 401) {
    //alert("");
    console.log('logout1');
    GlobalFunction.logOut();
    const navigate = useNavigate();
    navigate('/login'); // Utilisez navigate pour rediriger vers la page de connexion
    // Redirection vers la page de connexion
    //window.location.replace("/login");
    //window.location.href = "/login";
    
  } else if (error.response.status === 500) {
    window.location.href = window.location.origin + "/error-500";
  }
  return Promise.reject(error);
});

// Fonction pour désactiver temporairement les intercepteurs
// Dans AxiosInterceptor.js
export function disableInterceptors() {
  console.log('Interceptors disabled');
  axios.interceptors.request.eject(requestInterceptor);
  axios.interceptors.response.eject(responseInterceptor);
}


// Fonction pour réactiver les intercepteurs
export function enableInterceptors() {
  axios.interceptors.request.use(function (config) {
    if (localStorage.token != undefined) {
      config.headers['Authorization'] = `Bearer ${localStorage.token}`;
    }
    return config;
  }, function (error) {
    return Promise.reject(error);
  });

  axios.interceptors.response.use(function (response) {
    return response;
  }, function (error) {
    if (error.response.status === 401) {
      console.log('logout2');
      //alert("");
      GlobalFunction.logOut();
      const navigate = useNavigate();
      navigate('/login'); // Utilisez navigate pour rediriger vers la page de connexion
    // Redirection vers la page de connexion
    //window.location.replace("/login");
    //window.location.href = "/login";

    } else if (error.response.status === 500) {
      window.location.href = window.location.origin + "/error-500";
    }
    return Promise.reject(error);
  });
}

/*

import axios from "axios";
import GlobalFunction from './GlobalFunction';

axios.interceptors.request.use(function (config) {
  console.log('Request Interceptor:', config);
  if (localStorage.token !== undefined) {
    config.headers['Authorization'] = `Bearer ${localStorage.token}`;
  }
  return config;
}, function (error) {
  console.log('Request Interceptor Error:', error);
  return Promise.reject(error);
});

axios.interceptors.response.use(function (response) {
  console.log('Response Interceptor:', response);
  return response;
}, function (error) {
  console.log('Response Interceptor Error:', error);
  // Logique d'erreur existante
  if (error.response.status === 401) {
    GlobalFunction.logOut();
    console.log('logout');
  } else if (error.response.status === 500) {
    window.location.href = window.location.origin + "/error-500";
  }
  return Promise.reject(error);
});
*/