import React from 'react';
import { createBrowserRouter, Route, Routes } from 'react-router-dom';
import AuthLayout from '../layouts/AuthLayout';
import Login from '../modules/auth/Login';
import Error403 from '../modules/Error403';

const PublicRouter = createBrowserRouter([
  {
    path: '/',
    element: <AuthLayout />,
    children: [
      {
        path: '/',
        element: <Login />,
      },
      {
        path: '/login',
        element: <Login />,
      },
      {
        path: '/*',
        element: <Error403 />,
      },
      // pour tout autre chose qui sera saisie ramene Error403
/*
      {
        path: '/*',
        element: <Error403 />,
      },
*/
    ],
  },
]);

export default PublicRouter;
