import React, { useEffect, useState } from "react";
import BreadCrumb from "../../partials/BreadCrumb";
import CardHeader from "../../partials/miniComponent/CardHeader/CardHeader";
import { Link, useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import Constants from "../../../Constants";
import Swal from "sweetalert2";
import NoDataFound from "../../partials/miniComponent/NoDataFound";
import Loader from "../../partials/miniComponent/Loader";
import Pagination from "react-js-pagination";
import Form from "react-bootstrap/Form";
import { getUserPermissions } from "../../../services/authService";
import * as XLSX from "xlsx";
import "jspdf-autotable";
import { saveAs } from "file-saver";
import Select from "react-select";
import jsPDF from "jspdf";

//import { PDFDownloadLink, Document, Page, Text, View } from "react-pdf";
//import { PDFDownloadLink, Document, Page, Text, View } from "@react-pdf/renderer";
//import { PDFViewer, PDFDownloadLink, Document, Page, Text } from '@react-pdf-viewer';

const Dime = () => {
  const [userPermissions, setUserPermissions] = useState([]);
  const [errors, setErrors] = useState([]);
  const [categories, setCategories] = useState([]);
  const [uname, setUname] = useState([]);
  const [userNames, setUserNames] = useState({});
  const [totalMontant, setTotalMontant] = useState(0);
  const [selectedId, setSelectedId] = useState(null);
  const [selectedRole, setSelectedRole] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [matricules, setMatricules] = useState([]);
  const [annees, setAnnees] = useState([]);


  
  const [input, setInput] = useState({
    order_by: "created_at",
    per_page: 10,
    direction: "desc",
    search: "",
    mois: "",
    annee: "",
  });
  const [formErrors, setFormErrors] = useState("");
  const [userId, setUserId] = useState();
  const [dimeId, setDimeId] = useState();
  const [selectedFile, setSelectedFile] = useState(null); // Nouvel état pour stocker le fichier sélectionné
  const [roles, setRoles] = useState([]);
  const [input2, setInput2] = useState({});

  const handleRefresh = () => {
    setInput({
      order_by: "created_at",
      per_page: 10,
      direction: "desc",
      search: "",
      mois: "",
      annee: "",
    });

    // Effectuez une nouvelle recherche ici
    getDimes(1);
  };


  useEffect(() => {
    // Effectuer une requête pour récupérer les années depuis votre API
    //`${Constants.BASE_URL}/dimestatus?mois=${currentMonth}&annee=${currentYear}`
    //'http://192.168.2.1:8000/api/dimesannees'
    axios.get(`${Constants.BASE_URL}/dimesannees`)
      .then(response => {
        setAnnees(response.data.annees);
      })
      .catch(error => {
        console.error('Erreur lors de la récupération des années :', error);
      });
  }, []); // Cette dépendance vide indique que cet effet ne s'exécute qu'une seule fois lors du montage du composant



  const formattedOptions = matricules.map(matricule => ({
    value: matricule.id,
    label: matricule.matricule
}));


  const options = roles.map(role => ({
    value: role.id,
    label: role.matricule,
  }));

  const handleExportExcel = () => {
    const dataset = categories.map((dime, index) => ({
      Numéro: index + 1,
      Montant: dime.montant,
      Par: dime.user.name,
      Date: dime.created_at,
    }));

    const ws = XLSX.utils.json_to_sheet(dataset);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Dimes");

    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });

    const blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    saveAs(blob, "dimes.xlsx");
  };

  const handleExportPDF = () => {
    const pdf = new jsPDF();

    pdf.text("Liste des Dîmes", 10, 10);

    const tableData = categories.map((dime, index) => [
      index + 1,
      formatMontant(dime.montant),
      dime.user.name,
      dime.created_at,
    ]);
    function formatMontant(montant) {
      // Utiliser toLocaleString pour ajouter le séparateur de milliers
      const montantAvecSeparateur = montant.toLocaleString();

      // Concaténer "F CFA" à la fin
      return montantAvecSeparateur + " F CFA";
    }

    pdf.autoTable({
      head: [["Numéro", "Montant", "Par", "Date"]],
      body: tableData,
      startY: 20,
    });

    pdf.save("dimes.pdf");
  };


  const formatDateStringForInput = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = `${(date.getMonth() + 1).toString().padStart(2, '0')}`;
    const day = `${date.getDate().toString().padStart(2, '0')}`;
    const hours = `${date.getHours().toString().padStart(2, '0')}`;
    const minutes = `${date.getMinutes().toString().padStart(2, '0')}`;
    return `${year}-${month}-${day}T${hours}:${minutes}`;
  };
  // Fonction pour formater la date au format "yyyy-MM-ddThh:mm"
const formatDateStringForInput2 = (inputDateString) => {
  const inputDate = new Date(inputDateString);
  const year = inputDate.getFullYear();
  const month = (inputDate.getMonth() + 1).toString().padStart(2, '0'); // Ajoute un zéro devant si nécessaire
  const day = inputDate.getDate().toString().padStart(2, '0'); // Ajoute un zéro devant si nécessaire
  const hours = inputDate.getHours().toString().padStart(2, '0'); // Ajoute un zéro devant si nécessaire
  const minutes = inputDate.getMinutes().toString().padStart(2, '0'); // Ajoute un zéro devant si nécessaire

  return `${year}-${month}-${day}T${hours}:${minutes}`;
};

function convertToDateTimeLocalFormat(isoDateString) {
  const date = new Date(isoDateString);
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');

  return `${year}-${month}-${day}T${hours}:${minutes}`;
}


function formatDateString2(inputDateString) {
  // Vérifiez si la chaîne en entrée est non nulle et non indéfinie
  if (inputDateString) {
    // Créez une instance de Date à partir de la chaîne en entrée
    const inputDate = new Date(inputDateString);

    // Obtenez les composants de date
    const year = inputDate.getFullYear();
    const month = (inputDate.getMonth() + 1).toString().padStart(2, '0'); // Les mois commencent à 0, donc ajoutez 1
    const day = inputDate.getDate().toString().padStart(2, '0');
    const hours = inputDate.getHours().toString().padStart(2, '0');
    const minutes = inputDate.getMinutes().toString().padStart(2, '0');
    const seconds = inputDate.getSeconds().toString().padStart(2, '0');

    // Construisez la chaîne de format souhaitée
    const formattedString = `${day}/${month}/${year} à ${hours}:${minutes}:${seconds}`;

    return formattedString;
  }

  // Retournez une chaîne vide si l'entrée est nulle ou indéfinie
  return '';
}

// Exemple d'utilisation
//const inputDateString = "2023-12-20T09:51";
//const formattedDate = formatDateString(inputDateString);
//console.log(formattedDate);


// Définir la fonction de formatage du montant
function formatMontant(montant) {
  // Utiliser toLocaleString pour ajouter les séparateurs de milliers
  const montantAvecSeparateur = montant.toLocaleString('fr-FR');

  // Concaténer " F CFA" à la fin
  return montantAvecSeparateur + " F CFA";
}

const handleExportFilteredData = () => {
  axios
    .get(
      `${Constants.BASE_URL}/dimesanspagination?search=${input.search}&order_by=${input.order_by}&mois=${input.mois}&annee=${input.annee}&per_page=${input.per_page}&direction=${input.direction}`
    )
    .then((res) => {
      const filteredData = res.data.data;
      const pdf = new jsPDF();
      const logoPath = "logo_ad.png";
      pdf.addImage(logoPath, "PNG", 22, 5, 10, 10);

      const monthNames = [
        "Janvier",
        "Février",
        "Mars",
        "Avril",
        "Mai",
        "Juin",
        "Juillet",
        "Août",
        "Septembre",
        "Octobre",
        "Novembre",
        "Décembre",
      ];

      const currentDate = new Date();
      const formattedDate = `${currentDate.getDate()}/${
        currentDate.getMonth() + 1
      }/${currentDate.getFullYear()}`;
      const formattedTime = `${currentDate.getHours()}:${currentDate.getMinutes()}:${currentDate.getSeconds()}`;

      pdf.saveGraphicsState();
      pdf.setFont("helvetica", "bold");
      pdf.text(`Etat généré le ${formattedDate} à ${formattedTime}`, 60, 20);
      pdf.setFontSize(11); // Réduire la taille de la police pour les lignes suivantes
      pdf.line(10, 25, 200, 25);

      let yPos = 32;
      if (input.search) {
        pdf.text(`Etat pour: ${input.search}`, 10, yPos);
        yPos += 6;
      }
      if (input.mois) {
        const monthName = monthNames[input.mois - 1] || "";
        pdf.text(`Mois: ${monthName}`, 10, yPos);
        yPos += 6;
      }
      if (input.annee) {
        pdf.text(`Année: ${input.annee}`, 10, yPos);
        yPos += 6;
      }

      pdf.text("", 10, 70);

      const spaceBeforeTable = yPos > 32 ? yPos + 6 : 32;

      const tableData = filteredData.map((dime, index) => [
        index + 1,
        dime.created_at,
        parseFloat(dime.montant), // Convertir le montant en nombre et le formater
        dime.user.name,
        dime.user.role.name,
      ]);

      // Convertir chaque montant en nombre et calculer la somme
      const totalMontant = filteredData.reduce((total, dime) => total + parseFloat(dime.montant), 0);

      // Formater le total
      const formattedTotalMontant = totalMontant.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, " ");

      pdf.autoTable({
        head: [
          ["N°", "Date de Versement", "Montant de la Dîme", "Donné Par", "Qualité"],
        ],
        body: tableData,
        startY: spaceBeforeTable,
      });

      pdf.setFontSize(17);
      pdf.text(
        `Total : ${formattedTotalMontant} F CFA`,
        10,
        pdf.autoTable.previous.finalY + 10
      );

      pdf.save("etat-dimes.pdf");
    })
    .catch((error) => {
      console.error("Erreur lors de la récupération des données filtrées:", error);
    });
};





  const handleExportFilteredDataDimeStatus = () => {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1; // Les mois commencent à 0, donc ajoutez 1
    const currentYear = currentDate.getFullYear();
  
    axios
      .get(`${Constants.BASE_URL}/dimestatus?mois=${currentMonth}&annee=${currentYear}`)
      .then((res) => {
        const filteredData = res.data.data;
  
        const pdf = new jsPDF();
  
        const logoPath = "logo_ad.png"; // Assurez-vous de spécifier le chemin correct vers votre logo
        pdf.addImage(logoPath, "PNG", 22, 5, 10, 10);
  
        // Ajoutez l'en-tête
        pdf.setFontSize(10); // Réinitialisez la taille de la police à la taille normale
        // Texte à centrer
        const centerText =
          " Généré par Logiciel de suivi des dîmes - Eglises Assemblées de Dieu Temple de la Victoire";
  
        // Calculer la largeur du texte
        const textWidth = pdf.getTextWidth(centerText);
  
        // Centrer le texte
        const centerX = (pdf.internal.pageSize.width - textWidth) / 2;
  
        // Ajouter l'en-tête centré
        pdf.setFontSize(10); // Réinitialisez la taille de la police à la taille normale
        pdf.text(centerText, centerX, 10);
        pdf.text("", 10, 15);
        pdf.setFontSize(12); // Réinitialisez la taille de la police à la taille normale
        // Ajouter la date et l'heure actuelles
        const formattedDate = `${currentDate.getDate()}/${currentMonth}/${currentYear}`;
        const formattedTime = `${currentDate.getHours()}:${currentDate.getMinutes()}:${currentDate.getSeconds()}`;
  
        // Save the current font settings
        pdf.saveGraphicsState();
  
        // Set the font to bold
        pdf.setFont("helvetica", "bold");
  
        pdf.text(`Point des Dîmes du Mois - Etat généré le ${formattedDate} à ${formattedTime}`, 40, 20);
        //pdf.text(centerText, centerX, 10);
        // Ajoutez une ligne horizontale
        pdf.line(10, 25, 200, 25);
  
        // Ajoutez les critères de filtrage
        pdf.setFontSize(10);
        const monthNames = [
          "Janvier",
          "Février",
          "Mars",
          "Avril",
          "Mai",
          "Juin",
          "Juillet",
          "Août",
          "Septembre",
          "Octobre",
          "Novembre",
          "Décembre",
        ];
        // Ajouter les critères de filtrage
        pdf.setFontSize(10);
        let yPos = 32; // Position Y de départ pour les critères
  
        if (input.search) {
          pdf.text(`Etat pour: ${input.search}`, 10, yPos);
          yPos += 6; // Ajustez l'espace entre les éléments
        }
  
        pdf.text(`Mois: ${monthNames[currentMonth - 1]}`, 10, yPos); // Mois en cours
        yPos += 6; // Ajustez l'espace entre les éléments
  
        pdf.text(`Année: ${currentYear}`, 10, yPos); // Année en cours
        yPos += 6; // Ajustez l'espace entre les éléments
  
        pdf.text("", 10, 70);
  
        // Ajustez la position Y avant le tableau en fonction de la présence des critères
        const spaceBeforeTable = yPos > 32 ? yPos + 6 : 32;
  
        function getTextStyleByStatus(status) {
            if (status.toLowerCase() === 'pas à jour') {
              return { color: 'red', fontStyle: 'bold' };
            } else {
              return { color: 'black', fontStyle: 'normal' };
            }
          }
          
          
        function formatMontant(montant) {
            // Utiliser toLocaleString pour ajouter le séparateur de milliers
            const montantAvecSeparateur = montant
              .toFixed(0)
              .replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  
            // Concaténer "F CFA" à la fin
            return montantAvecSeparateur + " F CFA";
          }
  
        const tableData = filteredData.map((dime, index) => [
            index + 1,
            dime.user_name,   // Utilisez "user_name" au lieu de "user.name"
            formatMontant(parseFloat(dime.total_dimes)), // Assurez-vous que total_dimes est un nombre
            dime.status,
          ]);
          
          
          // Reste du code inchangé
          
            
        pdf.autoTable({
          head: [
            ["N°", "Donné Par", "Total de la Dîme", "Statut"],            
          ],
          body: tableData,
          startY: spaceBeforeTable, // Ajustez la position de départ pour la table en fonction de l'espace avant le tableau
        });
  
// Ajoutez cette fonction à votre code
function calculateTotalMontant(data) {
  return data.reduce((total, dime) => total + parseFloat(dime.montant), 0);
}

  
  // Ensuite, utilisez cette fonction dans votre code
  const totalMontant = calculateTotalMontant(filteredData);
  
  pdf.setFontSize(17);
  pdf.text(
    `Total : ${formatMontant(totalMontant)}`,
    10,
    pdf.autoTable.previous.finalY + 10
  );
  
  const monthName = monthNames[currentMonth - 1];
const fileName = `statut-de-versement-dime-${monthName.toUpperCase()}-${currentYear}.pdf`;

pdf.save(fileName);

  
      })
      .catch((error) => {
        console.error(
          "Erreur lors de la récupération des données filtrées:",
          error
        );
      });
  };
  
  
  
  // Fonction pour calculer le total du montant
  const calculateTotalMontant = (data) => {
    return data.reduce((total, dime) => total + dime.montant, 0);
  };


  // Fonction pour formater la date au format "yyyy-MM-ddThh:mm"
const formatDateString = (inputDateString) => {
  const options = { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' };
  const inputDate = new Date(inputDateString);
  return inputDate.toLocaleString('en-CA', options).replace(/,/, 'T');
};

  const handleExportFilteredDataExcel = () => {
    axios
      .get(
        `${Constants.BASE_URL}/dimesanspagination?&search=${input.search}&order_by=${input.order_by}&mois=${input.mois}&annee=${input.annee}&per_page=${input.per_page}&direction=${input.direction}`
      )
      .then((res) => {
        const filteredData = res.data.data;

        // Calculer le total des montants filtrés
        const total = filteredData.reduce((acc, dime) => acc + parseFloat(dime.montant), 0);
        function formatMontant(montant) {
          // Utiliser toLocaleString pour ajouter le séparateur de milliers
          const montantAvecSeparateur = montant.toLocaleString();
    
          // Concaténer "F CFA" à la fin
          return montantAvecSeparateur;
        }
        setTotalMontant(formatMontant(total));

        // Ajouter une ligne pour le total dans le tableau des données
        const totalRow = {
          Numéro: "Total Montant",
          Montant: total,
          Par: "", // Vous pouvez laisser cela vide ou ajouter une étiquette appropriée
          Date: "",
        };

        const dataset = filteredData.map((dime, index) => ({
          Numéro: index + 1,
          Montant: dime.montant,
          Par: dime.user.name,
          Date: dime.created_at,
        }));

        // Ajouter la ligne du total au début du tableau des données
        //dataset.unshift(totalRow);
        //la ligne a la fin du tableau
        dataset.push(totalRow);

        const ws = XLSX.utils.json_to_sheet(dataset);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Filtered Dimes");

        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });

        const blob = new Blob([excelBuffer], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });

        saveAs(blob, "dimes.xlsx");
      })
      .catch((error) => {
        console.error(
          "Erreur lors de la récupération des données filtrées:",
          error
        );
      });
  };

  // Utilisez handleExportFilteredData lorsque vous voulez exporter les données filtrées

  const fetchUserPermissions = async () => {
    try {
      const permissions = await getUserPermissions();
      setUserPermissions(permissions);
      //console.log(userPermissions)
    } catch (error) {
      console.error("Error fetching user permissions:", error);
    }
  };

  useEffect(() => {
    fetchUserPermissions();
  }, []);


  
  
  const handleInput2 = (e) => {
    const { name, value } = e.target;
    // Find the role in the roles array based on the selected matricule
    
    // Set the selected role in the state
    //console.log('TTTTest:',roles);
    //console.log('value:',value);
    //const selectI = roles.find(role => role.matricule === value);
    setSelectedId(value);  

  // Recherchez l'objet dans le tableau roles qui correspond à l'ID sélectionné
  if (value !== input2.uid) {
    // Si le champ matricule est modifié, recherchez l'objet dans le tableau roles
    const selectedRoleId = parseInt(value);
    const selectedRole = matricules.find(matricule => matricule.id === selectedRoleId);
    // Mise à jour de l'état selectedRole uniquement si la valeur a changé
    setSelectedRole(selectedRole);
  }

  // Mise à jour de l'état selectedRole
    // Update the input2 state if needed
    setInput2({
      ...input2,
      [name]: value,
    });
  };

  const handleInput33 = (selectedOption) => {
    // Obtenez l'ID du rôle sélectionné
    const selectedRoleId = selectedOption ? selectedOption.value : '';
  
    // Mettez à jour l'état input2
    setInput2({
      ...input2,
      uid: selectedRoleId,
    });
  
    // Si un rôle est sélectionné, trouvez l'objet correspondant dans le tableau roles
    const selectedRole = matricules.find(role => role.id === parseInt(selectedRoleId));
    console.log('selectedRole');
    console.log(selectedRole);
  
    // Mettez à jour l'état selectedRole
    setSelectedRole(selectedRole);
  };
   

  const handleInput22 = (e) => {    
    setInput2((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  /*
  const handleInput2 = (e) => {    
    setInput2((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };*/

  const handleInput3 = (selectedOption) => {
    setInput2({ uid: selectedOption ? selectedOption.value : "" });
  };

  const handleCloseModal = () => {
    // Réinitialiser les erreurs
    setFormErrors("");
    // Réinitialiser les données du formulaire
    setFormData({
      uid: "",
      montant: "",
      created_at: "",
    });

    setSelectedRole('');
    // Fermer la modal
    setModalShow(false);
  };
  const handleErrors = (error) => {
    if (error.response && error.response.status === 422) {
      setErrors(error.response.data.errors);
    } else {
      // Gérer d'autres erreurs ici si nécessaire
    }
  };
  const handleInput = (e) => {
    setInput((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
    //setFormData(prevState => ({...prevState, [e.target.name]: e.target.value}))
  };

  const [formData, setFormData] = useState({
    uid: "",
    montant: "",
    created_at: "",
  });

  const buildImagePath = (relativePath) => {
    // Remplacez 'http://localhost' par l'URL de base de votre site
    return `${relativePath}`;
  };

  const fetchDataForDime = async (id) => {
    try {
      const response = await axios.get(`${Constants.BASE_URL}/dimes/${id}`);
      return response.data.data;
    } catch (error) {
      // Gérez les erreurs ici
      console.error(
        "Erreur lors de la récupération des données utilisateur:",
        error
      );
      throw error; // Rejetez l'erreur pour indiquer qu'il y a eu un problème
    }
  };

  const updateUserListAfterDelete = (attributeId) => {
    // Filtrer les catégories pour exclure celle supprimée
    //const updatedAttributes = categories.filter(attribute => attribute.id !== attributeId);
    //setAttributes(updatedAttributes);
  };

  const handleUserDelete = (id) => {
    //console.log($id);
    Swal.fire({
      title: "Etes-vous sûr?",
      text: "Cet Utilisateur sera supprimé !",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Oui, supprimer!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios.delete(`${Constants.BASE_URL}/dimes/${id}`).then((res) => {
          //updateUserListAfterDelete(id); // Mettre à jour la liste après la suppression
          getDimes(1);
          Swal.fire({
            position: "top-end",
            icon: res.data.cls,
            title: res.data.msg,
            showConfirmButton: false,
            toast: true,
            timer: 1500,
          });
        });
      }
    });
  };

  const handleModal = async (category = undefined) => {
    // Réinitialisez les erreurs
    setErrors([]);

    if (category !== undefined) {
      try {
        // Utilisez les données de la catégorie directement au lieu de la fonction fetchDataForDime
        //console.log("ok")
        const userDataPromise = fetchDataForDime(category.id);
        //console.log(userDataPromise);

        userDataPromise.then((dimeData) => {
          setInput2(dimeData);
          setUserId(dimeData.uid); // Ajoutez cette ligne pour définir l'ID de l'utilisateur
          setDimeId(category.id); // Ajoutez cette ligne pour définir l'ID de l'utilisateur
          //console.log(dimeData.uid);
          //console.log('dateeeeeeee:',dimeData.created_brut_at);
          //console.log(fetchDataForDime(category.id));
          //console.log(fetchDataForUid(category.id));
          //console.log('categoryyyyy');
          //console.log(category);

          //console.log(dimeData);
        });

        //console.log("category.id");
        //console.log(category.id);
        console.log("nouvelle date:",category.created_at);
        setSelectedDate(category.created_brut_at);

        const dimeData = {
          uid: category.id,
          montant: category.montant,
          created_at: category.created_at,
        };

        //console.log(dimeData);

        // Définissez les valeurs du formulaire avec les données de l'utilisateur existant
        console.log('dateeeeeeee:',dimeData.created_at);
        setInput2({
          uid: dimeData.uid,
          montant: dimeData.montant,
          created_at: dimeData.created_at,
        });

        setModalTitle("Modifier");
        setIsEditMode(true);
        // Ouvrez la modal ici, après avoir récupéré les données de l'utilisateur
        setModalShow(true);

        /*                




               */
      } catch (error) {
        // Gérez l'erreur, peut-être affichez un message d'erreur à l'utilisateur
      }
    } else {
      // ... le reste du code pour la création d'utilisateur
      // Si category n'est pas défini, c'est une création d'utilisateur, réinitialisez l'ID
      setUserId(null);

      setModalTitle("Ajouter");
      setIsEditMode(false);

      // Réinitialisez les valeurs du formulaire pour une nouvelle création d'utilisateur
      setInput2({
        uid: "",
        montant: "",
        created_at: "",
      });

      // Ouvrez la modal
      setModalShow(true);
    }
  };

  const handleUserUpdate = () => {
    setIsLoading(true);

    const updateData = {
      uid: formData.uid.trim(),
      montant: formData.montant.trim(),
      created_at: formData.created_at.trim(),
    };

    // Créez un objet FormData
    const formDataObject = new FormData();
    Object.keys(updateData).forEach((key) => {
      formDataObject.append(key, updateData[key]);
    });
    //console.log(userId);
    //console.log(dimeId);
    //console.log(input2);
    //console.log(input2.uid);
    //console.log(input2.montant);
    //console.log(selectedDate);
    //console.log(input2.created_at);


    // Créez un nouvel objet
const updatedData2 = {
  uid: input2.uid,
  montant: input2.montant,
  created_at: input2.created_at !== undefined ? input2.created_at : selectedDate,
};

// Affichez les valeurs pour vérification
//console.log(updatedData2.uid);
//console.log(updatedData2.montant);
//console.log(updatedData2.created_at);
//console.log('updatedData2');
//console.log(updatedData2);

    //return; // Cette ligne arrête l'exécution ici

    axios
      .put(`${Constants.BASE_URL}/dimes/${dimeId}`, updatedData2)
      .then((response) => {
        console.log(response);
        setIsLoading(false);
        const dimeData = response.data;
        setInput2({
          uid: dimeData.uid,
          montant: dimeData.montant,
          created_at: dimeData.created_at,
        });
        
        Swal.fire({
          position: "top-end",
          icon: response.data.cls,
          title: response.data.msg,
          showConfirmButton: false,
          toast: true,
          timer: 1500,
        });

        setModalShow(false);
        getDimes(1);
        fetchUserPermissions();
      })
      .catch((errors) => {
        setIsLoading(false);

        if (errors.response.status === 422) {
          // Gérez les erreurs de validation spécifiques
          // Vous pouvez extraire les erreurs de la réponse et les afficher
          console.log(errors.response.data.errors);
          setFormErrors(errors.response.data.errors);
        } else {
          // Gérez d'autres erreurs ici
          console.log(errors);
        }
      });
  };

  const getDimes = (pageNumber) => {
    setIsLoading(true);
    axios
      .get(
        `${Constants.BASE_URL}/dimes?page=${pageNumber}&search=${input.search}&order_by=${input.order_by}&mois=${input.mois}&annee=${input.annee}&per_page=${input.per_page}&direction=${input.direction}`
      )
      .then((res) => {
        setCategories(res.data.data);
        // Calculer le total des montants
        const total = res.data.data.reduce(
          (acc, dime) => acc + parseFloat(dime.montant),
          0
        );

        function formatMontant(montant) {
          // Utiliser toLocaleString pour ajouter le séparateur de milliers
          const montantAvecSeparateur = montant.toLocaleString();
    
          // Concaténer "F CFA" à la fin
          return montantAvecSeparateur;
        }

        setTotalMontant(formatMontant(total));
        //setUname(fetchDataForUid(2));
        console.log("voici");
        console.log(res.data.data);
        setItemsCounterPerPage(res.data.meta.per_page);
        setStartFrom(res.data.meta.from);
        setTotalItemsCount(res.data.meta.total);
        setActivePage(res.data.meta.current_page);
        setIsLoading(false);
        console.log(res.data);
      });
  };

  const handleCreateSubmit = () => {
    setIsLoading(true);

    const formDataObject = new FormData();

    formDataObject.append("uid", input2.uid);
    formDataObject.append("montant", input2.montant);
    formDataObject.append("created_at", input2.created_at);
    //formDataObject.append('phone', input2.phone);
    //formDataObject.append('photo', input2.photo);
    //formDataObject.append('role_id', input2.role_id);

    axios
      .post(`${Constants.BASE_URL}/dimes`, formDataObject)
      .then((response) => {
        setIsLoading(false);

        if (response.data.errors) {
          console.log(response.data.errors);
          setFormErrors(response.data.errors);
          //setFormErrors(errors.response.data.errors);
          return;
        } else {
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Dîme enregistrée avec succès !",
            showConfirmButton: false,
            toast: true,
            timer: 1500,
          });

          //setErrors([]);
          // Réinitialisez les champs du formulaire après la soumission réussie
          setInput2({
            uid: "",
            montant: "",
            created_at: "",
          });

          // Fermez la modal après la soumission réussie
          setModalShow(false);

          // Rechargez la liste des utilisateurs après la création
          getDimes(1);
          fetchUserPermissions();
        }
      })
      .catch((error) => {
        setIsLoading(false);
        //console.log(error.response);
        // Gérer les erreurs
        //handleErrors(error);
      });
  };

  const getRoles = () => {
    axios
      .get(`${Constants.BASE_URL}/users`)
      .then((response) => {
        console.log(response);
        setRoles(response.data.data);
        //const matriculesArray = response.data.data.map(user => user.matricule);
        // Mettre à jour le state avec les matricules
        //setMatricules(matriculesArray);
      })
      .catch((error) => {
        //console.error('Erreur lors de la récupération des rôles:', error);
      });
  };
  const getMatricules = () => {
    axios
      .get(`${Constants.BASE_URL}/usersanspagination`)
      .then((response) => {
        console.log(response);
        //setMatricules(response.data.data);
        //const matriculesArray = response.data.data.map(user => user.matricule);
        // Mettre à jour le state avec les matricules
        setMatricules(response.data.data);
      })
      .catch((error) => {
        //console.error('Erreur lors de la récupération des rôles:', error);
      });
  };

  useEffect(() => {
    getMatricules();
    getRoles();
    getDimes();
    
    //getUser()
    //console.log('errors');
    //console.log(errors);
  }, []);

  const [modalShow, setModalShow] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  //const [permissions, setAttributes] = useState([]);
  const [permissions, setAttributes] = useState([]);

  const navigate = useNavigate();

  const [itemsCountPerPage, setItemsCounterPerPage] = useState(0);
  const [totalItemsCount, setTotalItemsCount] = useState(1);
  const [startFrom, setStartFrom] = useState("");
  const [activePage, setActivePage] = useState(1);
  const [modalTitle, setModalTitle] = useState("Add");
  const [valueModalTitle, setValueModalTitle] = useState("Add");
  const [valueModalShow, setValueModalShow] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);

  const [modalValue, setModalValue] = useState([]);
  const [modalValueShow, setModalValueShow] = useState(false);

  return (
    <>
      <BreadCrumb title={"Gestion des dîmes"} />
      {isLoading ? (
        <Loader />
      ) : (
        <>
          {console.log(
            "bloc 1 " +
              userPermissions.some(
                (permission) => permission.name === "view_user_account"
              )
          )}
          {userPermissions.some(
            (permission) => permission.name === "view_user_account"
          ) ? (
            <>
              {/* Your content goes here */}
              <div className="row">
                <div className="col-md-12">
                  <div
                    className="card mb-4"
                    style={{
                      boxShadow: "0 2px 20px rgba(0,0,0,.2)",
                      border: "0px",
                    }}
                  >
                    <div className="">
                      <div className="card-header">
                        <div className="d-flex justify-content-between align-items-center">
                          <h4 style={{ fontSize: 19 }} className={"text-theme"}>
                            <i
                              style={{ fontSize: 22 }}
                              className="fas fa-coins"
                            ></i>
                            &nbsp; Gestion des dîmes
                          </h4>
                          {userPermissions.some(
                            (permission) =>
                              permission.name === "create_user_account"
                          ) && (
                            <button
                              className={"btn btn3"}
                              onClick={() => handleModal()}
                              style={{
                                background: "#44abb6 !important",
                                fontWeight: "bolder",
                                fontSize: 18,
                              }}
                            >
                              <i className="fas fa-money-bill" /> &nbsp; Ajouter
                            </button>
                          )}
                        </div>
                      </div>

                      <div style={{ marginTop: 10, marginLeft: 10 }}>
                        <p style={{ fontWeight: "bolder", marginBottom: 6 }}>
                          Exportation intelligente{" "}
                        </p>
                        <Button
                          className={"btn btn-sm btn3"}
                          style={{
                            background: "#44abb6 !important",
                            fontWeight: "bolder",
                            fontSize: 18,
                            borderRadius: 20,
                          }}
                          onClick={handleExportFilteredData}
                        >
                          {" "}
                          &nbsp; <i className="fa fa-file-pdf"></i> PDF &nbsp;
                        </Button>
                        &nbsp;
                        <Button
                          className={"btn btn-sm btn3"}
                          style={{
                            background: "#44abb6 !important",
                            fontWeight: "bolder",
                            fontSize: 18,
                            borderRadius: 20,
                          }}
                          onClick={handleExportFilteredDataExcel}
                        >
                          {" "}
                          &nbsp; <i className="fa fa-file-excel"></i> Excel
                          &nbsp;
                        </Button>
                        &nbsp;
                        <Button
  className={"btn btn-sm btn5"}
  style={{
    backgroundColor: "white !important",
    boxShadow: "0 2px 1px rgba(0, 0, 0, 0.2)",
    border: '1px solid #cecece!important',
    fontWeight: "bolder",
    fontSize: 18,
    borderRadius: 20,
  }}
  onClick={handleExportFilteredDataDimeStatus}
>
                          {" "}
                          &nbsp; <i className="fa fa-file-pdf"></i> Point du Mois PDF &nbsp;
                        </Button>
                        &nbsp;
                        {/*
 <button className={'btn btn-sm theme-button3'} onClick={handleExportPDF}>
          Export PDF
        </button>

        <button className={'btn btn-sm theme-button3'} onClick={handleExportExcel}>
          Export Excel
        </button>
 */}
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="search-area mb-4" style={{ margin: 10 }}>
                        <div className="row search_bar">
                          <div className="col-md-3">
                            <label className={"w-100"}>
                              <p
                                style={{
                                  color: "#428bca",
                                  fontWeight: "bolder",
                                }}
                              >
                                matricule ou nom
                              </p>
                              <input
                                className="form-control form-control-sm"
                                name={"search"}
                                type={"search"}
                                value={input.search}
                                onChange={handleInput}
                                placeholder={"saisir ici..."}
                                style={{ height: "145px !important" }}
                              />
                            </label>
                          </div>
                          <div className="col-md-3">
                            <label className={"w-100"}>
                              <p
                                style={{
                                  color: "#428bca",
                                  fontWeight: "bolder",
                                }}
                              >
                                mois
                              </p>
                              
                              <select
                                className="form-select form-select-sm"
                                name={"mois"}
                                value={input.mois}
                                onChange={handleInput}
                              >
                                <option value={""}>selectionnez</option>
                                <option value={"1"}>Janvier</option>
                                <option value={"2"}>Février</option>
                                <option value={"3"}>Mars</option>
                                <option value={"4"}>Avril</option>
                                <option value={"5"}>Mai</option>
                                <option value={"6"}>Juin</option>
                                <option value={"7"}>Juillet</option>
                                <option value={"8"}>Août</option>
                                <option value={"9"}>Septembre</option>
                                <option value={"10"}>Octobre</option>
                                <option value={"11"}>Novembre</option>
                                <option value={"12"}>Décembre</option>
                              </select>
                            </label>
                          </div>
                          <div className="col-md-3">
                            <label className={"w-100"}>
                              <p
                                style={{
                                  color: "#428bca",
                                  fontWeight: "bolder",
                                }}
                              >
                                Année
                              </p>

                              <select
        className="form-select form-select-sm"
        name="annee"
        value={input.annee}
        onChange={handleInput}
      >
        <option value="">Sélectionnez</option>
        {annees.map(annee => (
          <option key={annee} value={annee}>{annee}</option>
        ))}
      </select>                              
               {/*
               <select
                                className="form-select form-select-sm"
                                name={"annee"}
                                value={input.annee}
                                onChange={handleInput}
                              >
                                <option value={""}>selectionnez</option>
                                <option value={"2020"}>2020</option>
                                <option value={"2021"}>2021</option>
                                <option value={"2022"}>2022</option>
                                <option value={"2023"}>2023</option>
                                <option value={"2024"}>2024</option>
                                <option value={"2025"}>2025</option>
                                <option value={"2026"}>2026</option>
                                <option value={"2027"}>2027</option>
                              </select>               
                */}
                            </label>
                          </div>
                          <div className="col-md-3">
                            <label className={"w-100"}>
                              <p
                                style={{
                                  color: "#428bca",
                                  fontWeight: "bolder",
                                }}
                              >
                                filtrer par
                              </p>
                              <select
                                className="form-select form-select-sm"
                                name={"order_by"}
                                value={input.order_by}
                                onChange={handleInput}
                              >
                                <option value={"montant"}>
                                  Montant de la Dîme
                                </option>
                                <option value={"created_at"}>
                                  Date de versement
                                </option>
                                {/**<option value={'updated_at'}>Upda</option> */}
                                <option value={"user.name"}>
                                  Nom utilisateur
                                </option>
                              </select>
                            </label>
                          </div>
                          <div className="col-md-3">
                            <label className={"w-100"}>
                              <p
                                style={{
                                  color: "#428bca",
                                  fontWeight: "bolder",
                                }}
                              >
                                sens de croissance
                              </p>
                              <select
                                className="form-select form-select-sm"
                                name={"direction"}
                                value={input.direction}
                                onChange={handleInput}
                              >
                                <option value={"asc"}>ASC</option>
                                <option value={"desc"}>DESC</option>
                              </select>
                            </label>
                          </div>
                          <div className="col-md-3">
                            <label className={"w-100"}>
                              <p
                                style={{
                                  color: "#428bca",
                                  fontWeight: "bolder",
                                }}
                              >
                                Nb d'enregistrement par page
                              </p>
                              <select
                                className="form-select form-select-sm"
                                name={"per_page"}
                                value={input.per_page}
                                onChange={handleInput}
                              >
                                <option value={10}>10</option>
                                <option value={25}>25</option>
                                <option value={50}>50</option>
                                <option value={100}>100</option>
                              </select>
                            </label>
                          </div>

                          <div className="col-md-1">
                            <div className="d-grid" style={{ marginTop: 20 }}>
                              <button
                                className={"btn btn-sm btn4"}
                                onClick={() => getDimes(1)}
                                style={{ borderRadius: 5 }}
                              >
                                <i className="fa-solid fa-magnifying-glass" />
                              </button>
                            </div>
                          </div>

                          <div className="col-md-1">
                            <div className="d-grid" style={{ marginTop: 20 }}>
                              <button
                                className={"btn btn-sm btn4"}
                                onClick={handleRefresh}
                              >
                                <i className="fa-solid fa-sync-alt" />
                              </button>
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div
                              className="d-grid"
                              style={{
                                float: "",
                                fontWeight: "bolder",
                                fontSize: "22px",
                                marginTop: 25,
                              }}
                            >                              
                              {totalMontant} F CFA
                            </div>
                          </div>

                          {/*
                                        
                                        <div className='col-md-2'>
                                            <div className='d-grid' style={{marginTop:20}}>
                                                <button className={'btn btn-sm theme-button2'} onClick={()=>handleModal()} style={{}}>
                                                    <i className='fa-solid fa-user-plus'/> &nbsp;
                                                    Ajouter
                                                </button>
                                            </div>
                                        </div>                                                                            
                                         */}
                        </div>
                      </div>
                      {isLoading ? (
                        <Loader />
                      ) : (
                        <div className="table-responsive soft-landing">
                          <table
                            className={
                              "my-table position-relative table table-hover table-striped table-bordered"
                            }
                          >
                            <thead>
                              <tr>
                                <th style={{ fontSize: 15 }}>N°</th>
                                <th style={{ fontSize: 15 }}>Enregistré le</th>
                                
                                <th style={{ fontSize: 15 }}>Montant</th>
                                <th style={{ fontSize: 15 }}>Donateur</th>
                                <th style={{ fontSize: 15 }}>Matricule</th>
                                <th style={{ fontSize: 15 }}>Qualité</th>
                                
                                <th style={{ fontSize: 15 }}>Action</th>
                              </tr>
                            </thead>
                            {console.log('categories')}
                            {console.log(categories)}
                            <tbody>
                              {Object.keys(categories).length > 0 ? (
                                categories.map((dime, index) => (
                                  <tr key={index}>
                                    <td style={{ fontSize: 14 }}>
                                      <p className={"text-theme"}>
                                        {index + 1}
                                      </p>
                                    </td>
                                    <td style={{ fontSize: 14 }}>
                                      <p className={"text-theme"}>
                                        <small>{dime.created_at}</small>
                                      </p>
                                      {/*
                                      <p className={'text-theme'}><small>Updated: {dime.updated_at}</small></p>                                            
                                                         */}
                                    </td>                                    
                                    <td style={{ fontSize: 14 }}>
                                      <p className={"text-theme"}>
                                        <i
                                          style={{ fontSize: 22 }}
                                          className="fas fa-coins"
                                        ></i>{" "}
                                        {dime.montant} F CFA
                                      </p>
                                    </td>
                                    <td style={{ fontSize: 14 }}>
                                      {/* Displaying user information */}
                                      <p className={"text-theme"}>
                                        {dime.user.name}
                                      </p>
                                    </td>
                                    <td style={{ fontSize: 14 }}>
                                      {/* Displaying user information */}
                                      <p className={"text-theme"}>
                                        {dime.user.matricule}
                                      </p>
                                    </td>
                                    <td style={{ fontSize: 14 }}>
                                      {/* Displaying user information */}
                                      <p className={"text-theme"}>
                                        {dime.user.role.name}
                                      </p>
                                    </td>


                                    <td>
                                      {/**<button onClick={()=>{handleDetailsModal(category)}} className={'btn btn-sm btn-info my-1'}><i className='fa-solid fa-eye'/></button>**/}
                                      {/**<Link to={`/users/edit/${category.id}`}><button className={'btn btn-sm btn-warning my-1 mx-1'}><i className='fa-solid fa-edit'/></button></Link>**/}
                                      {/* Button with onClick handler for editing user */}

                                      {userPermissions.some(
                                        (permission) =>
                                          permission.name ===
                                          "edit_user_account"
                                      ) && (
                                        <button
                                          style={{ borderRadius: 8 }}
                                          onClick={() => handleModal(dime)}
                                          className={
                                            "btn btn-sm btn-warning my-1 mx-1"
                                          }
                                        >
                                          <i
                                            className="fa-solid fa-edit"
                                            style={{ fontSize: 13 }}
                                          />{" "}
                                          modifier
                                        </button>
                                      )}
                                      {userPermissions.some(
                                        (permission) =>
                                          permission.name ===
                                          "delete_user_account"
                                      ) && (
                                        <button
                                          style={{ borderRadius: 8 }}
                                          onClick={() => {
                                            handleUserDelete(dime.id);
                                          }}
                                          className={
                                            "btn btn-sm btn-danger my-1"
                                          }
                                        >
                                          <i
                                            className="fa-solid fa-trash"
                                            style={{ fontSize: 13 }}
                                          />{" "}
                                          supprimer
                                        </button>
                                      )}
                                    </td>
                                  </tr>
                                ))
                              ) : (
                                <NoDataFound />
                              )}
                            </tbody>
                          </table>
                        </div>
                      )}
                    </div>
                    <div className="card-footer">
                      <nav className={"pagination-sm"}>
                        <Pagination
                          activePage={activePage}
                          itemsCountPerPage={itemsCountPerPage}
                          totalItemsCount={totalItemsCount}
                          pageRangeDisplayed={5}
                          onChange={getDimes}
                          nextPageText={"Next"}
                          firstPageText={"first"}
                          prevPageText={"Previous"}
                          lastPageText={"last"}
                          itemClass={"page-item"}
                          linkClass={"page-link"}
                        />
                      </nav>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            // Afficher l'alerte seulement si l'utilisateur n'a pas la permission
            <>
              {!userPermissions.some(
                (permission) => permission.name === "view_user_account"
              ) && (
                <>
                  <div
                    className="alert alert-danger"
                    role="alert"
                    style={{ display: "" }}
                  >
                    Vous n'avez pas le droit d'accéder à ce contenu.
                  </div>
                </>
              )}
            </>
          )}
        </>
      )}

      <Modal show={modalShow} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            {" "}
            <i style={{ fontSize: 22 }} className="fas fa-coins"></i>{" "}
            {modalTitle} Dîme
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <label
            className={`w-100 ${
              errors && errors.uid !== undefined ? "mb-4" : ""
            }`}
          >
            <p>Pour qui effectuez-vous l'enregistrement?</p>

{console.log('options')}
{console.log(matricules)}
<Select
  className={
    errors && errors.uid !== undefined
      ? "form-select mt-2 is-invalid"
      : "form-select mt-2"
  }
  name={'uid'}
  value={formattedOptions.find(option => option.value === input2.uid)}
  onChange={handleInput33}
  options={formattedOptions}
  placeholder="Sélectionnez une personne"
  isSearchable
/>


 {/*
 <Select
  className={
    errors && errors.uid !== undefined
      ? "form-select mt-2 is-invalid"
      : "form-select mt-2"
  }
  name={'uid'}
  value={options.find((option) => option.value === input2.uid)}
  onChange={handleInput33}
  options={options}
  placeholder="Sélectionnez une personne"
  isSearchable
/>
  */}


{selectedRole ? (
  <label style={{ marginTop: 20 }}>
    {selectedRole.matricule} - {selectedRole.name}
  </label>
) : ''}


{/*
      <select
        className={errors && errors.uid !== undefined ? 'form-select mt-2 is-invalid' : 'form-select mt-2'}
        name={'uid'}
        value={input2.uid}
        onChange={handleInput2}
    >
        <option value={''}>Sélectionnez une personne</option>
        {          
          Array.isArray(roles) && roles.map(role => (
            <option key={role.id} value={role.id}>
                {role.matricule}
            </option>
        ))}
    </select>
 */}



{console.log('roles: ', roles)}
            {formErrors &&
              formErrors.role_id &&
              formErrors.role_id.length > 0 && (
                <p className={"login-error-msg"} style={{ marginBottom: 20 }}>
                  <small>{formErrors.role_id[0]}</small>
                  <br />
                </p>
              )}


{/*

              <input
                className={formErrors && formErrors.uid !== undefined ? 'form-control mt-2 is-invalid' : 'form-control mt-2'}
                type={'text'}
                name={'uid'}
                value={selectedId}
                onChange={handleInput2}
                placeholder={''}
            />
 */}


{/* Affichez le matricule et le nom dans le label */}

{/*
  selectedRole?
(<label style={{marginTop:20}}> {selectedRole ? selectedRole.matricule : ''} - {selectedRole ? selectedRole.name : ''}</label>):
''*/
}



          </label>
          {formErrors &&
            formErrors.role_id &&
            formErrors.role_id.length > 0 && (
              <>
                <br />
                <br />
              </>
            )}

          <label
            className={`w-100 ${
              formErrors && formErrors.montant !== undefined ? "mb-4" : ""
            }`}
            style={{ marginTop: 10 }}
          >
            <p>Saisir le montant de la dîme:</p>
            <input
              className={
                formErrors && formErrors.montant !== undefined
                  ? "form-control mt-2 is-invalid"
                  : "form-control mt-2"
              }
              type={"text"}
              name={"montant"}
              value={input2.montant}
              onChange={handleInput22}
              //onChange={(e) => setFormData({ ...formData, name: e.target.value })}
              placeholder={""}
            />
            {formErrors &&
              formErrors.montant &&
              formErrors.montant.length > 0 && (
                <p className={"login-error-msg"} style={{ marginBottom: 20 }}>
                  <small>{formErrors.montant[0]}</small>
                </p>
              )}
          </label>
          <label
            className={`w-100 ${
              formErrors && formErrors.created_at !== undefined ? "mb-4" : ""
            }`}
            style={{ marginTop: 10 }}
          >
          {console.log(input2)}
            {/*<p>Saisir la date: {input2.created_at}</p>*/}
            <p>Saisir la date: 
             {/*formatDateStringForInput(selectedDate)*/}            
             </p>

            {/*formatDateStringForInput("2024-02-01T09:55:00.000000Z")*/}
            <input
  className={
    formErrors && formErrors.created_at !== undefined
      ? "form-control mt-2 is-invalid"
      : "form-control mt-2"
  }
  type={"datetime-local"}
  name={"created_at"}

  value={
    isEditMode
      ? selectedDate
        ? formatDateStringForInput(selectedDate)
        : formatDateStringForInput(input2.created_at)
      : input2.created_at
  }

  onChange={handleInput22}
  placeholder={""}
/>

{isEditMode && formatDateStringForInput(selectedDate) && formatDateStringForInput(input2.created_at) !== selectedDate && formatDateStringForInput(input2.created_at) !== 'NaN-NaN-NaNTNaN:NaN' && (
  <p style={{marginTop:10}}>La nouvelle date sera: <b>{formatDateString2(input2.created_at)}</b></p>
)}


            {formErrors &&
              formErrors.created_at &&
              formErrors.created_at.length > 0 && (
                <p className={"login-error-msg"} style={{ marginBottom: 20 }}>
                  <small>{formErrors.created_at[0]}</small>
                </p>
              )}
          </label>

          {/***            <Button variant="primary" className={'theme-button3 mt-4 float-end'} onClick={handleCreateSubmit}>
                Créer le Compte
            </Button> */}

          <button
            style={{ fontSize: 17, fontWeight: "bolder" }}
            className={"btn btn3 float-end mt-4"}
            onClick={
              isEditMode ? () => handleUserUpdate(userId) : handleCreateSubmit
            }
            dangerouslySetInnerHTML={{
              __html: isLoading
                ? '<span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span> Loading ...'
                : `${modalTitle} Dîme`,
            }}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};
export default Dime;
