import React from 'react';
import $ from 'jquery';
import logo from '../../assets/img/log.png';
import swal from 'sweetalert2';
import axios from 'axios';
import Constants from '../../Constants';
import GlobalFunction from '../../GlobalFunction'
import { Link, useNavigate } from 'react-router-dom';

const Nav = () => {
    const navigate = useNavigate();  // Ajout de useNavigate
    const handleSidebar = () => {
        $('body').toggleClass('sb-sidenav-toggled')
    }

    const handleLogout = () =>{
        swal.fire({
            title: 'Êtes-vous sûr?',
            text: "Vous serez déconnecté !",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Oui, se déconnecter!',
            cancelButtonText: 'Annuler'  // Personnalisez le texte du bouton Annuler ici
          }).then((result) => {
            if (result.isConfirmed) {
                axios.post(`${Constants.BASE_URL}/logout`).then(res=>{
                    //console.log(res.data);
                    GlobalFunction.logOut();
                    navigate('/');  // Rediriger vers la page de connexion après la déconnexion                    
                    window.location.reload();
                }).catch(errors => {
                    GlobalFunction.logOut();
                });
            }
          })
    }

    const handleProfile = () =>{
        navigate('/profil');
    }

    
    return (
        <nav class="sb-topnav navbar navbar-expand navbar-dark bg-theme">
            <Link className="navbar-brand ps-3" to="/">
                <img src={logo} alt={'logo'} className={'img-thumbnail w-50'} style={{marginTop:47}}/>
            </Link>
            <button onClick={handleSidebar} class="btn btn-link btn-sm order-1 order-lg-0 me-4 me-lg-0" id="sidebarToggle" href="#!"><i class="fas fa-bars"></i></button>
            
            <ul class="navbar-nav align-items-center ms-auto me-3 me-lg-4">
            <p style={{color:'gray !important;'}} className='text-whit'>{localStorage.name != undefined ? localStorage.name : null}</p>
                <li class="nav-item dropdown">
                    <a style={{color:'gray !important;'}}  class="nav-link dropdown-toggle" id="navbarDropdown" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false"><i class="fas fa-user fa-fw"></i></a>
                    <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown">
                        <li><button onClick={handleProfile} class="dropdown-item" href="#!">Mon Profil</button></li>
                        <li><button onClick={handleLogout} class="dropdown-item" href="#!">Deconnexion</button></li>
                    </ul>
                </li>
            </ul>
        </nav>
    );
};

export default Nav;