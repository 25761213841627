const Constants = {
    //BASE_URL : 'https://tplvictoire.coach-lewi.com/api'
    //BASE_URL : 'http://localhost:8000/api',    
    //BASE_URLSIMPLE : 'http://localhost:8000',
    //BASE_URL : 'https://clients.cynomedia.com/api'
    
    BASE_URL : 'https://tplvictoire.coach-lewi.com/api',
    BASE_URLSIMPLE : 'https://tplvictoire.coach-lewi.com'

    //BASE_URL : 'http://192.168.2.1/api',
    //BASE_URLSIMPLE : 'http://192.168.2.1'
}

export default Constants