import React from 'react';
import {Link} from 'react-router-dom';


const SideBar = () => {



    return (
        <div id="layoutSidenav_nav">
        <nav class="sb-sidenav accordion bg-theme-basic" id="sidenavAccordion">
            <div class="sb-sidenav-menu">

            <div class="nav">

                    <div class="sb-sidenav-menu-heading" style={{marginBottom:-50}}><i class="fa-brands fa-cpanel" style={{fontSize:90, color:'white'}}/></div>
                    <div class="sb-sidenav-menu-heading">Bienvenue</div>
                    <Link class="nav-link" to="/">
                        <div class="sb-nav-link-icon"><i class="fas fa-tachometer-alt"></i></div>
                        Tableau de Bord
                    </Link>

                    <div class="sb-sidenav-menu-heading">Modules</div>
                    <a class="nav-link collapsed" href="#" data-bs-toggle="collapse" data-bs-target="#collapseLayouts" aria-expanded="false" aria-controls="collapseLayouts" style={{textDecoration:'none'}}>
                        <div class="sb-nav-link-icon"><i class="fa fa-lock"></i></div>
                         Permissions
                        <div class="sb-sidenav-collapse-arrow"><i class="fas fa-angle-down"></i></div>
                    </a>
                    <div class="collapse" id="collapseLayouts" aria-labelledby="headingOne" data-bs-parent="#sidenavAccordion">
                        <nav class="sb-sidenav-menu-nested nav">
                            <Link class="nav-link" to="/permissions/">Gérer Permissions</Link>
                        </nav>
                    </div>
                    <a class="nav-link collapsed" href="#" data-bs-toggle="collapse" data-bs-target="#sub_category" aria-expanded="false" aria-controls="sub_category" style={{textDecoration:'none'}}>
                        <div class="sb-nav-link-icon"><i class="fa fa-user-group"></i></div>
                         Rôles
                        <div class="sb-sidenav-collapse-arrow"><i class="fas fa-angle-down"></i></div>
                    </a>
                    <div class="collapse" id="sub_category" aria-labelledby="headingOne" data-bs-parent="#sidenavAccordionSubCategorie">
                        <nav class="sb-sidenav-menu-nested nav">
                            <Link class="nav-link" to="/roles/">Gérer Les Rôles</Link>
                        </nav>
                    </div>


                    <a class="nav-link collapsed" href="#" data-bs-toggle="collapse" data-bs-target="#visibility" aria-expanded="false" aria-controls="visibility">
                        <div class="sb-nav-link-icon"><i class="fa-solid fa-people-group"></i></div>
                        Comité
                        <div class="sb-sidenav-collapse-arrow"><i class="fas fa-angle-down"></i></div>
                    </a>
                    <div class="collapse" id="visibility" aria-labelledby="headingOne" data-bs-parent="#sidenavAccordionVisibility">
                    <nav class="sb-sidenav-menu-nested nav">
                            <Link class="nav-link" to="/comites/">Gérer Les Comités</Link>
                        </nav>
                    </div>

                    <a class="nav-link collapsed" href="#" data-bs-toggle="collapse" data-bs-target="#brand" aria-expanded="false" aria-controls="brand" style={{textDecoration:'none'}}>
                        <div class="sb-nav-link-icon"><i class="fas fa-users"></i></div>
                            Utilisateurs
                        <div class="sb-sidenav-collapse-arrow"><i class="fas fa-angle-down"></i></div>
                    </a>
                    <div class="collapse" id="brand" aria-labelledby="headingOne" data-bs-parent="#sidenavAccordionbrand">
                        <nav class="sb-sidenav-menu-nested nav">
                            <Link class="nav-link" to="/users">Gérer Utilisateurs</Link>
                        </nav>
                    </div>
                    <a class="nav-link collapsed" href="#" data-bs-toggle="collapse" data-bs-target="#predication" aria-expanded="false" aria-controls="predication" style={{textDecoration:'none'}}>
                        <div class="sb-nav-link-icon"><i class="fas fa-bible"></i></div>
                            Prédications
                        <div class="sb-sidenav-collapse-arrow"><i class="fas fa-angle-down"></i></div>
                    </a>
                    <div class="collapse" id="predication" aria-labelledby="headingOne" data-bs-parent="#sidenavAccordionpredication">
                        <nav class="sb-sidenav-menu-nested nav">
                            <Link class="nav-link" to="/predications">Gérer Prédications</Link>
                        </nav>
                    </div>
                    <a class="nav-link collapsed" href="#" data-bs-toggle="collapse" data-bs-target="#annonce" aria-expanded="false" aria-controls="annonce" style={{textDecoration:'none'}}>
                        <div class="sb-nav-link-icon"><i class="fas fa-info-circle"></i></div>
                            Annonces
                        <div class="sb-sidenav-collapse-arrow"><i class="fas fa-angle-down"></i></div>
                    </a>
                    <div class="collapse" id="annonce" aria-labelledby="headingOne" data-bs-parent="#sidenavAccordionannonce">
                        <nav class="sb-sidenav-menu-nested nav">
                            <Link class="nav-link" to="/annonces">Gérer Annonces</Link>
                        </nav>
                    </div>

                    <a class="nav-link collapsed" href="#" data-bs-toggle="collapse" data-bs-target="#dime" aria-expanded="false" aria-controls="dime" style={{textDecoration:'none'}}>
                        <div class="sb-nav-link-icon"><i class="fas fa-coins"></i></div>
                            Dîmes
                        <div class="sb-sidenav-collapse-arrow"><i class="fas fa-angle-down"></i></div>
                    </a>
                    <div class="collapse" id="dime" aria-labelledby="headingOne" data-bs-parent="#sidenavAccordiondime">
                        <nav class="sb-sidenav-menu-nested nav">
                            <Link class="nav-link" to="/dimes">Gérer les dîmes </Link>
                        </nav>
                        <nav class="sb-sidenav-menu-nested nav">
                            <Link class="nav-link" to="/statistiques-dimes">Statistiques </Link>
                        </nav>
                    </div>


                    {/*
<a class="nav-link collapsed" href="#" data-bs-toggle="collapse" data-bs-target="#projet" aria-expanded="false" aria-controls="projet">
                        <div class="sb-nav-link-icon"><i class="fas fa-columns"></i></div>
                            Etats PDF
                        <div class="sb-sidenav-collapse-arrow"><i class="fas fa-angle-down"></i></div>
                    </a>
                    <div class="collapse" id="projet" aria-labelledby="headingOne" data-bs-parent="#sidenavAccordionprojet">
                        <nav class="sb-sidenav-menu-nested nav">
                        <Link class="nav-link" to="/documents">Etat dîmes utilisateur</Link>
                        </nav>
                    </div>                    
                     */}



{/*
                    <Link class="nav-link" to="product-attributes">
                        <div class="sb-nav-link-icon"><i class="fas fa-table"></i></div>
                        Paramètres
                    </Link>
 */}
                </div>


            </div>
            <div class="sb-sidenav-footer bg-theme text-silver">
                <div class="small">connecté en tant que:</div>
                {localStorage.name != undefined ? localStorage.name : null}
            </div>
        </nav>
    </div>
    );
};

export default SideBar;