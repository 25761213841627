// src/components/DocumentsPage.js

import React, { useState } from 'react';
import FolderCreator from '../../FolderCreator';
import FolderList from '../../FolderList';
import FolderForm from '../../FolderForm';

const DocumentsPage = () => {
    // État local pour gérer la mise à jour après la création d'un dossier
    const [isFolderCreated, setIsFolderCreated] = useState(false);

    // Fonction de rappel pour gérer la mise à jour après la création d'un dossier
    const handleFolderCreate = () => {
        // Mettez à jour l'état local pour déclencher la mise à jour de FolderList
        setIsFolderCreated(!isFolderCreated);
    };

    return (
        <div>
            
            
            {/* Formulaire de création de dossier */}
        
            {/* Liste des dossiers */}
            {/*<FolderForm/>*/}
            <FolderList/>
        </div>
    );
};

export default DocumentsPage;
