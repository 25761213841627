import React, { useEffect, useState, useRef } from "react";
import BreadCrumb from "../../partials/BreadCrumb";
import axios from "axios";
import Constants from "../../../Constants";
import NoDataFound from "../../partials/miniComponent/NoDataFound";
import Loader from "../../partials/miniComponent/Loader";
import { getUserPermissions } from "../../../services/authService";
import { Bar } from "react-chartjs-2";
import Chart from "chart.js/auto";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
//import MonthlyTotalChart from './monthlyTotalChart';
import domtoimage from 'dom-to-image';
import jsPDF from 'jspdf';

const StatDime = () => {
  const [userPermissions, setUserPermissions] = useState([]);
  const [categories, setCategories] = useState([]);
  const [totalMontant, setTotalMontant] = useState(0);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [categoriesByRole, setCategoriesByRole] = useState([]);
  const [comitesData, setComitesData] = useState([]);
  const [annees, setAnnees] = useState([]);



  const [input, setInput] = useState({
    order_by: "created_at",
    direction: "desc",
    annee: selectedYear,
  });

  const fetchUserPermissions = async () => {
    try {
      const permissions = await getUserPermissions();
      setUserPermissions(permissions);
    } catch (error) {
      console.error("Erreur lors de la récupération des autorisations de l'utilisateur :", error);
    }
  };



  useEffect(() => {
    // Effectuer une requête pour récupérer les années depuis votre API
    //`${Constants.BASE_URL}/dimestatus?mois=${currentMonth}&annee=${currentYear}`
    //'http://192.168.2.1:8000/api/dimesannees'
    axios.get(`${Constants.BASE_URL}/dimesannees`)
      .then(response => {
        setAnnees(response.data.annees);
      })
      .catch(error => {
        console.error('Erreur lors de la récupération des années :', error);
      });
  }, []); // Cette dépendance vide indique que cet effet ne s'exécute qu'une seule fois lors du montage du composant




  const getComitesData = () => {
    axios.get(`${Constants.BASE_URL}/total-dimes-by-comite/${selectedYear}`).then((res) => {
      setComitesData(res.data.data);
    }).catch(error => {
      console.error("Erreur lors de la récupération des données des comités :", error);
    });
  };

  const getDimesByRole = () => {
    axios.get(`${Constants.BASE_URL}/total-dimes-by-role/${selectedYear}`).then((res) => {
      const adjustedData = res.data.data.map((item) => ({
        role: item.role,
        total: parseFloat(item.total || 0), // Convertir en nombre, utiliser 0 si la valeur est vide
      }));
  
      setCategoriesByRole(adjustedData);
    });
  };
  
  

  const RoleTotalChart = ({ data }) => {
    const options = {
      chart: {
        type: 'bar',
      },
      title: {
        text: '',//Total des Dîmes par Qualité (Ancien...)
      },
      xAxis: {
        categories: data.labels,
      },
      yAxis: {
        min: 0,
        title: {
          text: 'Total',
        },
      },
      legend: {
        enabled: false,
      },
      tooltip: {
        pointFormat: 'Total: <b>{point.y:.1f} F CFA</b>',
      },
      series: [
        {
          name: 'Total',
          colorByPoint: true,
          data: data.datasets[0].data.map((total, index) => ({
            name: data.labels[index],
            y: total,
          })),
          dataLabels: {
            enabled: true,
            rotation: -90,
            color: '#FFFFFF',
            align: 'right',
            format: '{point.y:.1f} F CFA',
            y: 10,
            style: {
              fontSize: '13px',
              fontFamily: 'Verdana, sans-serif',
            },
          },
        },
      ],
    };
  
    return <HighchartsReact highcharts={Highcharts} options={options} />;
  };
  

  useEffect(() => {
    fetchUserPermissions();
    getDimesByRole();
    getComitesData(); // Appeler la fonction pour récupérer les données des comités lors du changement d'année
  }, [selectedYear]);


  const ComiteTotalChart = ({ data }) => {
    const options = {
      chart: {
        type: 'column', // Changement du type de graphique en 'column'
      },
      title: {
        text: '',//Total des Dîmes par Comité
      },
      xAxis: {
        categories: data.map(item => item.comite),
        labels: {
          rotation: -45, // Rotation des étiquettes à -45 degrés
        },
      },
      yAxis: {
        min: 0,
        title: {
          text: 'Total',
        },
      },
      legend: {
        enabled: false,
      },
      tooltip: {
        pointFormat: 'Total: <b>{point.y:.1f} F CFA</b>',
      },
      series: [
        {
          name: 'Total',
          colorByPoint: true,
          data: data.map(item => parseFloat(item.total)),
          dataLabels: {
            enabled: true,
            rotation: -90,
            color: '#FFFFFF',
            align: 'right',
            format: '{point.y:.1f} F CFA',
            y: 10,
            style: {
              fontSize: '13px',
              fontFamily: 'Verdana, sans-serif',
            },
          },
        },
      ],
    };
  
    return <HighchartsReact highcharts={Highcharts} options={options} />;
  };
  
  const MonthlyTotalAreaChart = ({ data }) => {
    const options = {
      chart: {
        type: 'areaspline', // Utiliser le type 'areaspline' pour un graphique de zone spline
      },
      title: {
        text: '',//Total des Dîmes par Mois
      },
      xAxis: {
        categories: data.map(item => item.month),
        labels: {
          rotation: -45,
        },
      },
      yAxis: {
        title: {
          text: 'Total',
        },
      },
      legend: {
        enabled: false,
      },
      tooltip: {
        pointFormat: 'Total: <b>{point.y:.1f} F CFA</b>',
      },
      series: [
        {
          name: 'Total',
          data: data.map(item => parseFloat(item.total)),
          marker: {
            enabled: true,
            symbol: 'circle',
          },
        },
      ],
    };
  
    return <HighchartsReact highcharts={Highcharts} options={options} />;
  };
  
  const MonthlyTotalChart = ({ data }) => {
    console.log("Data for MonthlyTotalChart:", data); // Vérifiez les données
  
    const options = {
      chart: {
        type: 'column',
      },
      title: {
        text: '',//Total des Dîmes par Mois
      },
      xAxis: {
        type: 'category',
        labels: {
          autoRotation: [-45, -90],
          style: {
            fontSize: '13px',
            fontFamily: 'Verdana, sans-serif',
          },
        },
      },
      yAxis: {
        min: 0,
        title: {
          text: 'Total',
        },
      },
      legend: {
        enabled: false,
      },
      tooltip: {
        pointFormat: 'Total: <b>{point.y:.1f} F CFA</b>',
      },
      series: [
        {
          name: 'Total',
          colorByPoint: true,
          groupPadding: 0,
          data: data.map((item) => ({
            name: item.month,
            y: item.total,
          })),
          dataLabels: {
            enabled: true,
            rotation: -90,
            color: '#FFFFFF',
            align: 'right',
            format: '{point.y:.1f} F CFA',
            y: 10,
            style: {
              fontSize: '13px',
              fontFamily: 'Verdana, sans-serif',
            },
          },
        },
      ],
    };
  
    console.log("Options for MonthlyTotalChart:", options); // Vérifiez les options
  
    return <HighchartsReact highcharts={Highcharts} options={options} />;
  };
  
  const dataByRole = {
    labels: categoriesByRole.map((item) => item.role),
    datasets: [
      {
        label: "Montant total par rôle",
        backgroundColor: "#e74c3c",
        borderColor: "#e74c3c",
        borderWidth: 1,
        hoverBackgroundColor: "#c0392b",
        hoverBorderColor: "#c0392b",
        data: categoriesByRole.map((item) => item.total),
      },
    ],
  };

  const getDimes = () => {
    axios.get(`${Constants.BASE_URL}/total-dimes-by-month/${selectedYear}`).then((res) => {
        const adjustedData = res.data.data.map((item) => ({
          month: item.month,
          total: parseFloat(item.total || 0), // Convertir en nombre, utiliser 0 si la valeur est vide
        }));
      
        setCategories(adjustedData);
      
        const total = adjustedData.reduce((acc, dime) => acc + dime.total, 0);
        setTotalMontant(total);
      });
  };

  useEffect(() => {
    getDimes();
  }, [selectedYear]);

  const chartRef = useRef(null);

  const data = {
    labels: categories.map((item) => item.month),
    datasets: [
      {
        label: "Montant total",
        backgroundColor: "#0a5fa2",
        borderColor: "#0a5fa2",
        borderWidth: 1,
        hoverBackgroundColor: "#0a5fa2",
        hoverBorderColor: "#0a5fa2",
        data: categories.map((item) => item.total),
      },
    ],
  };

  const options = {
    scales: {
      x: {
        type: 'category',
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
        display: true,
        color: 'black',
        formatter: (value, context) => {
          return value.toLocaleString();
        },
      },
    },
  };

  useEffect(() => {
    if (chartRef.current) {
      chartRef.current.destroy();
    }

    const newChart = new Chart(chartRef.current, {
      type: 'bar',
      data,
      options,
    });

    chartRef.current = newChart;

    return () => {
      if (chartRef.current) {
        chartRef.current.destroy();
      }
    };
  }, [data]);

  return (
    <>
      <BreadCrumb title={"Statistiques des dîmes"} />
      {userPermissions.some(
        (permission) => permission.name === "view_user_account"
      ) ? (
        <>
          <div className="row">
            <div className="col-md-12">
              <div
                className="card mb-4"
                style={{
                  boxShadow: "0 2px 20px rgba(0,0,0,.2)",
                  border: "0px",
                }}
              >
                <div className="">
                  <div className="card-header">
                    <div className="d-flex justify-content-between align-items-center">
                      <h4 style={{ fontSize: 19 }} className={"text-theme"}>
                        <i
                          style={{ fontSize: 22 }}
                          className="fas fa-coins"
                        ></i>{" "}
                        &nbsp; Statistiques des dîmes
                      </h4>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="search-area mb-4" style={{ margin: 10 }}>
                    <div className="row search_bar">
                      <div className="col-md-3">
                        <label className={"w-100"}>
                          <p
                            style={{
                              color: "#428bca",
                              fontWeight: "bolder",
                            }}
                          >
                            Année
                          </p>
                          <select
                            className="form-select form-select-sm"
                            name={"annee"}
                            value={selectedYear}
                            onChange={(e) => setSelectedYear(e.target.value)}
                            >
                              <option value="">Sélectionnez</option>
                              {annees.map(annee => (
                                <option key={annee} value={annee}>{annee}</option>
                              ))}
                          </select>                          

                          {/*
                          
                          <select
                            className="form-select form-select-sm"
                            name={"annee"}
                            value={selectedYear}
                            onChange={(e) => setSelectedYear(e.target.value)}
                          >
                                <option value={"2020"}>2020</option>
                                <option value={"2021"}>2021</option>
                                <option value={"2022"}>2022</option>
                                <option value={"2023"}>2023</option>
                                <option value={"2024"}>2024</option>
                                <option value={"2025"}>2025</option>
                                <option value={"2026"}>2026</option>
                                <option value={"2027"}>2027</option>
                          </select>
                           */}
                        </label>
                      </div>

                      <div className="col-md-3">
                        <div
                          className="d-grid"
                          style={{
                            float: "",
                            fontWeight: "bolder",
                            fontSize: "20px",
                            marginTop: 15,
                          }}
                        >                          
                          Total annuel: {totalMontant} F CFA
                        </div>
                      </div>
                    </div>
                  </div>
                  {categories.length > 0 ? (
                    <>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="mb-4">
                            <MonthlyTotalChart data={categories} />                            
                            
                            <div className="text-center">
                              <p className="legend-text" style={{fontWeight:"bolder"}}>Total des Dîmes Par Mois</p>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div>
                          <RoleTotalChart data={dataByRole} />
                            <div className="text-center">
                              <p className="legend-text" style={{fontWeight:'bolder'}}>Total des Dîmes par Qualité (Ancien...)</p>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* Nouvelle section pour le tableau */}
                      <div className="row mt-4">
                        <div className="col-md-6">
                                                      {/* Utilisation du nouveau composant pour afficher le diagramme des comités */}
                                                      <ComiteTotalChart data={comitesData} />
                            
                            <div className="text-center">
                              <p className="legend-text" style={{fontWeight:"bolder"}}>Total des Dîmes par Comité</p>
                            </div>


                        </div>
                        <div className="col-md-6">
                        <MonthlyTotalAreaChart data={categories} />                            
                            
                            <div className="text-center">
                              <p className="legend-text" style={{fontWeight:"bolder"}}>Total des Dîmes Par Mois</p>
                            </div>


                        </div>
                      </div>

                      <div className="row">
      <div className="col-md-12">
        <div className="mb-4">
        
        </div>
      </div>
    </div>

                    </>
                  ) : (
                    <NoDataFound />
                  )}
                </div>
                <div className="card-footer"></div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          {!userPermissions.some(
            (permission) => permission.name === "view_user_account"
          ) && (
            <>
              <div
                className="alert alert-danger"
                role="alert"
                style={{ display: "" }}
              >
                Vous n'avez pas le droit d'accéder à ce contenu.
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

export default StatDime;
