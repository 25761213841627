import React, { useEffect, useState } from 'react';
import BreadCrumb from '../../partials/BreadCrumb';
import CardHeader from '../../partials/miniComponent/CardHeader/CardHeader';
import { Link, useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';
import Constants from '../../../Constants';
import Swal from 'sweetalert2';
import NoDataFound from '../../partials/miniComponent/NoDataFound';
import Loader from '../../partials/miniComponent/Loader';
import Pagination from 'react-js-pagination';
import { getUserPermissions } from '../../../services/authService';

import GlobalFunction from '../../../GlobalFunction';


const PredicationList = (props) => {
    const [userPermissions, setUserPermissions] = useState([]);
    const [modalShow, setModalShow] = useState(false);
    //const [input, setInput] = useState({ name: '' });
    const [input, setInput] = useState({
        order_by: 'created_at',
        per_page: 10,
        direction: 'desc',
        search: '',
    });
    const [file, setFile] = useState(null);

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
        //console.log(e.target.files[0].name)
    };
    ;
    const [errors, setErrors] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    //const [predications, setAttributes] = useState([]);
    const [predications, setAttributes] = useState([]);

    const navigate = useNavigate();

    const [itemsCountPerPage, setItemsCounterPerPage] = useState(0);
    const [totalItemsCount, setTotalItemsCount] = useState(1);
    const [startFrom, setStartFrom] = useState('');
    const [activePage, setActivePage] = useState(1);
    const [modalTitle, setModalTitle] = useState('Add');
    const [valueModalTitle, setValueModalTitle] = useState('Add');
    const [valueModalShow, setValueModalShow] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);
    
    const [modalValue, setModalValue] = useState([]);
    const [modalValueShow, setModalValueShow] = useState(false);
  
    const handleRefresh = () => {
        setInput({
            order_by: 'created_at',
            per_page: 10,
            direction: 'desc',
            search: '',
        });
    
        // Effectuez une nouvelle recherche ici
        getPermissions(1);
      };
    
      
    const fetchUserPermissions = async () => {
        try {
          const predications = await getUserPermissions();
          setUserPermissions(predications);
          //console.log(userPermissions)
        } catch (error) {
          console.error('Error fetching user predications:', error);
        }
      };
  

    useEffect(() => {
      fetchUserPermissions();
    }, []);

          

    const updatePredicationListAfterDelete = (permissionId) => {
        // Filtrer les catégories pour exclure celle supprimée
        const updatedPermissions = predications.filter(predication => predication.id !== permissionId);
        setAttributes(updatedPermissions);
      };


      const handlePermissionCreate = () => {
        setIsLoading(true);
    
        const formData = new FormData();
        formData.append('label', input.label);
        formData.append('audio_file', file); // Utilisez le fichier audio sélectionné par l'utilisateur
        console.log(input);
        console.log(formData);
    
        axios.post(`${Constants.BASE_URL}/predications`, formData)
            .then(res => {
                setIsLoading(false);
                console.log(res.data);
    
                Swal.fire({
                    position: 'top-end',
                    icon: res.data.cls,
                    title: res.data.msg,
                    showConfirmButton: false,
                    toast: true,
                    timer: 1500
                });
    
                setErrors([]);
                setModalShow(false);
                getPermissions();
            })
            .catch(error => {
                setIsLoading(false);
                if (error.response.status === 422) {
                    setErrors(error.response.data.errors);
                }
            });
    }
    

    const handleValueCreate = () =>{
        
        console.log(input);
        setIsLoading(true);
        axios.post(`${Constants.BASE_URL}/predications`, input)
        .then(res => {
            setIsLoading(false);
            console.log(res.data);
            Swal.fire({
                position: 'top-end',
                icon: res.data.cls,
                title: res.data.msg,
                showConfirmButton: false,
                toast: true,
                timer: 1500
            });
    
            setErrors([]);
            setInput({ label: '' }); // Réinitialiser le champ "name"
            setModalShow(false);
            getPermissions();
        })
        .catch(errors => {
            setIsLoading(false);
            if (errors.response.status === 422) {
                setErrors(errors.response.data.errors);
            }
        });
    

    }


    const handleValueEdit = (id) =>{
        console.log(input);
        setIsLoading(true);
        axios.put(`${Constants.BASE_URL}/predications/${input.id}`, input)
        .then(res => {
            setIsLoading(false);
            console.log(res.data);
            Swal.fire({
                position: 'top-end',
                icon: res.data.cls,
                title: res.data.msg,
                showConfirmButton: false,
                toast: true,
                timer: 1500
            });
    
            setErrors([]);
            setInput({ label: '' });
            setValueModalShow(false);
            //setModalShow(false);
            getPermissions();
        })
        .catch(errors => {
            setIsLoading(false);
            if (errors.response.status === 422) {
                setErrors(errors.response.data.errors);
            }
        });

    }


    const handlePermissionDelete = (id) => {

        Swal.fire({
            title: 'Etes-vous sûr?',
            text: "Cette Permission sera supprimée !",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Oui, supprimer!'
          }).then((result) => {
            if (result.isConfirmed) {
                axios.delete(`${Constants.BASE_URL}/predications/${id}`).then(res=>{
                    updatePredicationListAfterDelete(id); // Mettre à jour la liste après la suppression
                    Swal.fire({
                        position: 'top-end',
                        icon: res.data.cls,
                        title: res.data.msg,
                        showConfirmButton: false,
                        toast: true,
                        timer: 1500
                    })
           
                });
            }
          })

    }
    


    const handleModal = (predication = undefined) => {    
        if (predication !== undefined) {
            setModalTitle('Modifier');
            setIsEditMode(true);
            console.log(predication.label);
            console.log(predication.audio_file);
            setInput(prevInput => ({
                ...prevInput,
                label: predication.label, // Mettez à jour le label avec la valeur de la prédication existante
                id: predication.id,
                audio_file: predication.audio_file // Mettez à jour le fichier audio avec la valeur de la prédication existante
            }));
            setFile(predication.audio_file);
        } else {
            setIsEditMode(false);
            setModalTitle('Ajouter');
            setInput({ label: '', audio_file: null }); // Réinitialisez le label et le fichier audio lors de l'ajout d'une nouvelle prédication
        }
        setErrors([]);
        setModalShow(true);                    
    };
    

    const handlePermissionUpdate = (editPredicationId) => {
        setIsLoading(true);
        console.log(predications);
        //console.log('input');
        //console.log(input);

        //console.log('file');
        //console.log(file);

        const formData = new FormData();
        formData.append('label', input.label);
        formData.append('audio_file', file.name); // Utilisez le fichier audio sélectionné par l'utilisateur

        console.log('voila',file.audio_file);
        //console.log(input);
        //console.log(formData);
   
        if (file) {
            formData.append('audio_file', file); // Utilisez le fichier audio sélectionné par l'utilisateur
        }
    
        // Affichez le nom du fichier audio
        console.log('Nom du fichier audio :', file ? file.name : 'Aucun fichier sélectionné');
   

        axios.put(`${Constants.BASE_URL}/predications/${editPredicationId}`, input)
            .then(res => {
                setIsLoading(false);
                console.log(res.data);
                Swal.fire({
                    position: 'top-end',
                    icon: res.data.cls,
                    title: res.data.msg,
                    showConfirmButton: false,
                    toast: true,
                    timer: 1500
                });
                setErrors([]);
                setInput({ ...input, label: '' }); // Réinitialisez le formulaire
                setModalShow(false);
                getPermissions();
                fetchUserPermissions();
            })
            .catch(errors => {
                setIsLoading(false);
                if (errors.response.status === 422) {
                    setErrors(errors.response.data.errors);
                }
            });
    };
   

    const handleInput = (e) => { setInput((prevState) => ({ ...prevState, [e.target.name]: e.target.value }));};
    
    const getPermissions = async (pageNumber = 1) => {
        setIsLoading(true);
        try {

          const response = await axios.get(`${Constants.BASE_URL}/predications?page=${pageNumber}&search=${input.search || ''}&order_by=${input.order_by || ''}&per_page=${input.per_page || ''}&direction=${input.direction || ''}`);
    
          setIsLoading(false);
          setAttributes(response.data.data);
          setItemsCounterPerPage(response.data.meta.per_page);
          setStartFrom(response.data.meta.from);
          setTotalItemsCount(response.data.meta.total);
          setActivePage(response.data.meta.current_page);
        } catch (error) {
          setIsLoading(false);
          console.error('Error fetching predications:', error);
          // Gérez les erreurs ici
        }
      };

      useEffect(() => {
        getPermissions();
      }, []);

    useEffect(() => {
        // Ce code sera exécuté à chaque mise à jour de predications
        console.log(predications);
        console.log('isEditMode');
        console.log(isEditMode);
    }, [predications]);  // Utilisez predications comme dépendance ici



    return (
        <>

        
          <BreadCrumb title={'Prédications'}/>  
          {isLoading ? (<Loader />):
          
          <>
          {console.log('bloc 1 '+userPermissions.some(predication => predication.name === 'view_permission'))}
          {userPermissions.some(predication => predication.name === 'view_permission') ? (
        <>
          {/* Your content goes here */}
          <div className='row'>
                <div className='col-md-12'>
                <div className='card mb-4' style={{boxShadow:'0 2px 20px rgba(0,0,0,.2)', border:'0px'}}>
                    <div className="card-header">
                            <div className='d-flex justify-content-between align-items-center'>
                                <h4 style={{fontSize:19}} className={'text-theme'}><i className='fa fa-bible' style={{fontSize:20}}></i> Espace Prédications </h4>
                                    <Link to={props.link}>
                                    {userPermissions.some(predication => predication.name === 'create_permission') && (
                                        <button className={'btn btn3'} onClick={()=>handleModal()} style={{background:'#44abb6 !important', fontWeight:'bolder', fontSize:18}}>
                                            <i className={`fas fa-plus`} style={{fontSize:20}}/>&nbsp;
                                            Ajouter &nbsp; {props.button_text} 
                                        </button>
                                    )}
                                    </Link>
                            </div>
                        </div>

                        <div className="card-body" style={{background:'white)'}}>
                        <div className='search-area mb-4' style={{margin:10}}>
                                    <div className='row search_bar'>
                                        <div className='col-md-4'>
                                            <label className={'w-100'}>
                                                <p style={{color:'#428bca', fontWeight:'bolder', marginBottom:10}}>mot clé</p>
                                                <input
                                                    className='form-control form-control-sm'
                                                    name={'search'}
                                                    type={'search'}
                                                    value={input.search}
                                                    onChange={handleInput}
                                                    placeholder={'saisir une predication...'}
                                                    style={{height:'145px !important'}}
                                                />
                                            </label>
                                        </div>
                                        <div className='col-md-2'>
                                            <label className={'w-100'}>
                                                <p style={{color:'#428bca', fontWeight:'bolder', marginBottom:10}}>filtrer par</p>
                                                <select
                                                    className='form-select form-select-sm'
                                                    name={'order_by'}
                                                    value={input.order_by}
                                                    onChange={handleInput}
                                                >
                                                    <option value={'name'}>Nom des predications</option>
                                                    <option value={'created_at'}>Date</option>
                                                </select>
                                            </label>
                                        </div>
                                        <div className='col-md-2'>
                                            <label className={'w-100'}>
                                                <p style={{color:'#428bca', fontWeight:'bolder', marginBottom:10}}>sens de croissance</p>
                                                <select
                                                    className='form-select form-select-sm'
                                                    name={'direction'}
                                                    value={input.direction}
                                                    onChange={handleInput}
                                                >
                                                    <option value={'asc'}>ASC</option>
                                                    <option value={'desc'}>DESC</option>
                                                </select>
                                            </label>
                                        </div>
                                        <div className='col-md-2'>
                                            <label className={'w-100'}>
                                            <p style={{color:'#428bca', fontWeight:'bolder', marginBottom:10}}>Enregistr. par page</p>
                                                <select
                                                    className='form-select form-select-sm'
                                                    name={'per_page'}
                                                    value={input.per_page}
                                                    onChange={handleInput}
                                                >
                                                    <option value={10}>10</option>
                                                    <option value={25}>25</option>
                                                    <option value={50}>50</option>
                                                    <option value={100}>100</option>
                                                </select>
                                            </label>
                                        </div>
                                        <div className='col-md-1'>
                                            <div className='d-grid' style={{marginTop:32}}>
                                                <button className={'btn btn-sm btn4'} onClick={()=>getPermissions(1)} style={{borderRadius:5}}>
                                                    <i className='fa-solid fa-magnifying-glass'/>
                                                </button>
                                            </div>
                                        </div>                                    
                                        <div className="col-md-1">
                            <div className="d-grid" style={{ marginTop: 32 }}>
                              <button
                                className={"btn btn-sm btn4"}
                                onClick={handleRefresh}
                              >
                                <i className="fa-solid fa-sync-alt" />
                              </button>
                            </div>
                          </div>                                        

                                    </div>
                                </div>

                            <div className='row'>
                                <div className='col-md-12'>

                                </div>                                {isLoading ? <Loader/> :
                                        <div className='table-responsive soft-landing'>
                                        <table className={'my-table position-relative table table-hover table-striped table-bordered'}>
                                            <thead>
                                                <tr>
                                                    <th style={{fontSize:15}}>N°</th>                                                    
                                                    <th style={{fontSize:15, width:'35%'}}>Theme</th>                                                    
                                                    <th style={{fontSize:15}}>Audio </th>
                                                    <th style={{fontSize:15}}>Date</th>
                                                    <th style={{fontSize:15}}>Action</th>
                                                </tr>
                                            </thead>

                                            <tbody>                                            
                                            {Object.keys(predications).length > 0 ? predications.map((predication, index) =>(
                                                    <tr key={index}>
                                                    <td style={{fontSize:14}}>
                                                    {index+1}
                                                    </td>
                                                    <td>
                                                    {predication.label}
                                                    </td>
                                                    <td style={{fontSize:14}}>                                                        
                                                        
                                                        <audio controls>
                                                        <source src={`${Constants.BASE_URLSIMPLE}/${predication.audio_file}`} type="audio/mpeg" />

                
            </audio>
                                                    </td>

                                                    <td style={{fontSize:14}}>
                                                        <p className={'text-theme'}><small>{predication.created_at}</small></p>
                                                        {/*<p className={'text-theme'}><small>Updated: {predication.updated_at}</small></p>*/}
                                                    </td>
                                                    <td style={{fontSize:13}}>
                                                    {userPermissions.some(predication => predication.name === 'edit_permission') && (
                                                        <button style={{borderRadius:8}} onClick={() => handleModal(predication)} className={'btn btn-sm btn-warning my-1 mx-1'}>
                                                        <i className='fa-solid fa-edit' style={{fontSize:13}}/> modifier 
                                                        </button>
                                                    )}
                                                    {userPermissions.some(predication => predication.name === 'delete_permission') && (
                                                        <button style={{borderRadius:8}} onClick={() => { handlePermissionDelete(predication.id) }} className={'btn btn-sm btn-danger my-1'}>
                                                        <i className='fa-solid fa-trash' style={{fontSize:13}}/> supprimer 
                                                        </button>
                                                    )}
                                                    </td>
                                                </tr>                                            
                                                
                                            )): <NoDataFound/>}
                                            </tbody>
                                            
                                        </table>
                                        </div>
                                }

                            </div>
                        </div>
                        <div className='card-footer'>
                                <nav className={'pagination-sm'}>
                                    <Pagination
                                        activePage={activePage}
                                        itemsCountPerPage={itemsCountPerPage}
                                        totalItemsCount={totalItemsCount}
                                        pageRangeDisplayed={5}
                                        onChange={getPermissions}
                                        nextPageText={'Next'}
                                        firstPageText={'first'}
                                        prevPageText={'Previous'}
                                        lastPageText={'last'}
                                        itemClass={'page-item'}
                                        linkClass={'page-link'}
                                    />
                                </nav>
                            </div>                            
                    </div>
                </div>
            </div>              

        </>
        ) : (
        // Afficher l'alerte seulement si l'utilisateur n'a pas la predication
<>
{!userPermissions.some(predication => predication.name === 'view_permission') && (
<>
<div className='alert alert-danger' role='alert' style={{display:''}}>
    Vous n'avez pas le droit d'accéder à ce contenu.
</div>
</>
)}

</>
      )}    

          </>
          }        
          

            <Modal
            centered
            show={modalShow}
            onHide={()=>setModalShow(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-center">
                    <i style={{fontSize:22}} className='fas fa-lock'></i> {modalTitle} Une Prédication
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>


                                    <label className={'w-100'}>
                                        <p>Thème de la predication</p>
                                        <input
                                            className={errors && errors.name !== undefined ? 'form-control mt-2 is-invalid' : 'form-control mt-2'}
                                            type={'text'}
                                            name={'label'}
                                            value={input.label}
                                            onChange={handleInput}
                                            placeholder={'saisir le Thème'}
                                        />

                                        <p className={'login-error-msg'}>
                                            <small>
                                                {errors && errors.name != undefined ? errors.name[0] : null}
                                            </small>
                                        </p>

                                    </label>
{/* Champ d'upload de fichier MP3 */}
{!isEditMode && (
<label className={'w-100 mt-3'}>
    
    <label className={''}>
        <p>Uploader un fichier MP3</p>
        <input
            type="file"
            className="form-control"
            name='audio_file'
            accept="audio/mp3"
            onChange={handleFileChange}
        />
    </label>

</label>
)}
{input.audio_file && (
    <audio controls style={{marginTop:15}}>
        <source src={`${Constants.BASE_URLSIMPLE}/${input.audio_file}`} type="audio/mpeg" />
    </audio>
)}

                                    
                                    <button
                                        style={{fontSize:17, fontWeight:'bolder'}}
                                        className={'btn btn3 mt-4 float-end'} 
                                        onClick={isEditMode ? ()=> handlePermissionUpdate(input.id):handlePermissionCreate }                                             
                                        dangerouslySetInnerHTML={{ __html: isLoading ? 
                                        '<span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span> Loading ...' 
                                        : 
                                        `${modalTitle}` }}                                            
                                        />

                </Modal.Body>

            </Modal>   

 
        </>
    );
};



export default PredicationList;