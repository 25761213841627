import React from 'react';

const Footer = () => {
    return (
        <footer class="py-1 bg-theme mt-auto" style={{background:'transparent', boxShadow:'none'}}>
        <div class="container-fluid px-4 py-3">
            <div class="d-flex align-items-center justify-content-between small">
                <small style={{float:'left'}} class="text-silver">Copyright &copy; AD TEMPLE DE LA VICTOIRE {new Date().getFullYear()} | Version: 0.1.0 Beta</small>
                <div>
                    <small style={{float:'right'}} class="text-silver">Conçu et developpé par <a href='#' className={'text-whit'}>DSI AD TEMPLE DE LA VICTOIRE</a></small>
                </div>
            </div>
        </div>
    </footer>
    );
};

export default Footer;