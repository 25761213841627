// Exemple de fonction getUserPermissions (dans authService.js)
import axios from 'axios';
import Constants from '../Constants';

export const getUserPermissions = async () => {
  try {
    const response = await axios.get(`${Constants.BASE_URL}/user/permissions`); // Remplacez par le vrai point de terminaison
    return response.data.permissions || [];
  } catch (error) {
    console.error('Error fetching user permissions:', error);
    return [];
  }
};


// src/services/authService.js
/*
import axios from 'axios';
import Constants from '../Constants';


export  const getUserPermissions = async () => {
  try {
    const response = await axios.get(`${Constants.BASE_URL}/user/permissions`);
    //setUserPermissions(response.data); // Utilisez response.data pour obtenir les données
    //console.log('User Permissions:', response.data.permissions); // Ajoutez cette ligne pour voir les données dans la console log
    return response.data.permissions; // Ajoutez cette ligne pour voir les données dans la console log
  } catch (error) {
    console.error('Error fetching user permissions:', error);
    // Vous pouvez traiter les erreurs ici ou retourner une valeur par défaut, par exemple :
    // setUserPermissions([]);
  }
};*/